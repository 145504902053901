import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { message } from 'antd';
import classNames from 'classnames';
import { compact } from 'lodash';
import { Empty } from '@/components/layouts/empty';
import { Button } from '@/components/widgets/button';
import { Icon } from '@/components/widgets/icon';
import { TextArea } from '@/components/widgets/input';
import { Dialog, Modal, useDialogMethods } from '@/components/widgets/modal';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { EsItem } from '@/types/esItem';
import { Option, Select } from '@/components/widgets/select';
import useDebounceFn from '@/hooks/useDebounceFn';
import { warnError } from '@/components/infos';
import chatpeerRequest from '@/requests/chatpeer';
import Loading from '@/components/widgets/loading/Loading';
import { VirtualDiv } from '@/components/layouts/virtualList';
import { Dropdown } from '@/components/widgets/dropdown';
import { Menu, MenuItem } from '@/components/widgets/menu';
import { ItemHelpTooltip } from '../itemHelpTooltip';
import { GoodsItem } from '../goodsItem';
import { ItemSelectorModel } from './model';
import { GoodsSelectModalProps } from './type';
import css from './style.less';
import { ApproveStatus } from '@/types/approveStatus';

const placeholder = '支持商品ID/商品标题/商家编码';
const AUTO_SIZE = { minRows: 1, maxRows: 6 };
const SCROLL_TRIGGER_DISTANCE = 5;

const PatchSelectDialog = memo(
  (props: DialogDeployProps<{ ids: string[]; limit: number }>) => {
    const {
      closeDialog,
      params: { ids = [], limit }
    } = props;

    const [value, setValue] = useState('');
    const handleOk = async () => {
      if (!value.trim()) {
        message.warning('请输入商品ID');
        return;
      }
      const patchList = compact(value.trim().split('\n')) || [];
      if (ids.length + patchList.length > limit) {
        message.warning(`至多选择${limit}个商品（已选择${ids.length}个）`);
        closeDialog();
        return;
      }
      try {
        const {
          itemList,
          repeatTaobaoIids,
          addedTaobaoIids,
          notFoundTaobaoIids
        } = await chatpeerRequest.batchFetchList({
          searchTaobaoIids: value,
          selectedTaobaoIids: ids
        });
        const { length } = [...addedTaobaoIids, ...ids];
        if (length > 5000) {
          if (ids.length) {
            message.warning(`最多选择5000个商品（已选择${ids.length}个`);
          } else {
            message.warning('最多选择5000个商品');
          }
          return;
        }
        closeDialog({
          itemList,
          repeatIds: [...new Set(repeatTaobaoIids)],
          repeatItemId: [...new Set(addedTaobaoIids)],
          notFoundTaobaoIids: [...new Set(notFoundTaobaoIids)]
        });
      } catch (e) {
        warnError(e);
      }
    };

    return (
      <Dialog
        title="批量选择商品"
        onCancel={() => closeDialog(null)}
        wrapClassName={css.modal}
        width={540}
        onOk={handleOk}
      >
        <div>
          <div>请将商品ID换行输入到输入框中</div>
          <div>
            <TextArea
              autoSize={{ minRows: 12, maxRows: 12 }}
              placeholder={'示例：\n655173962383\n655173962384'}
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      </Dialog>
    );
  }
);

const tipDialog = memo(
  (
    props: DialogDeployProps<{
      successNum: number;
      repeatIds: string[];
      repeatItemId: string[];
      notFoundTaobaoIids: string[];
    }>
  ) => {
    const {
      closeDialog,
      params: { successNum, repeatIds, repeatItemId, notFoundTaobaoIids }
    } = props;

    return (
      <Dialog
        title="选择结果"
        onCancel={closeDialog}
        wrapClassName={css.modal}
        width={540}
        onOk={closeDialog}
        footer={
          <Button type="primary" onClick={closeDialog}>
            确定
          </Button>
        }
      >
        <div>
          <div className={css.tipTitle}>
            选择成功<span>{successNum}</span>个商品
          </div>
          {repeatIds.length ? (
            <div>
              <div className={css.grayColor}>商品ID重复：</div>
              <div>{repeatIds.join('、')}</div>
            </div>
          ) : null}
          {repeatItemId.length ? (
            <div>
              <div className={css.grayColor}>重复选择商品：</div>
              <div>{repeatItemId.join('、')}</div>
            </div>
          ) : null}
          {notFoundTaobaoIids.length ? (
            <div>
              <div className={css.grayColor}>未找到商品：</div>
              <div>{notFoundTaobaoIids.join('、')}</div>
            </div>
          ) : null}
        </div>
      </Dialog>
    );
  }
);

const ItemClearDropdown = memo(
  (props: { clearItem: () => void; clearItemInStock: () => void }) => {
    const { clearItem, clearItemInStock } = props;

    return (
      <Dropdown
        overlay={
          <Menu>
            <MenuItem key="clearItem" onClick={clearItem}>
              清空全部商品
            </MenuItem>
            <MenuItem key="clearItemInstock" onClick={clearItemInStock}>
              清空仓库中商品
            </MenuItem>
          </Menu>
        }
        placement="bottomRight"
      >
        <div className={css.clear}>
          <span>清空商品</span>
          <Icon type="xiala" style={{ marginLeft: '4px' }} />
        </div>
      </Dropdown>
    );
  }
);

export default memo((props: GoodsSelectModalProps) => {
  const {
    limit,
    modalAction,
    selectLittleItem,
    selectRecommendItem,
    items,
    ids,
    onChange
  } = props;
  const [openDialog] = useDialogMethods();
  const [loading, setLoading] = useState(true);

  const isSearching = useRef(false);

  const {
    state,
    interaction,
    setKeyword,
    handleApproveStatusChange,
    setEditing,
    initial,
    fetchList,
    addItems,
    removeItem,
    clearItem,
    clearItemInStock,
    getSelectItems
  } = useAgentReducer(new ItemSelectorModel(items));

  const {
    selectList = [],
    page,
    searchValue,
    dataSource,
    searchApproveStatus,
    isEditing,
    total
  } = state;
  const scrollView = useRef<HTMLDivElement | null>(null);
  const taobaoIids = new Set(selectList?.map(item => item.taobaoIid));

  useEffect(() => {
    interaction.implement({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      changeItems(items) {
        onChange(items);
      }
    });
  }, []);

  // 滚动左边商品列表
  const scrollTo = (position: number) => {
    const element = scrollView.current;
    if (element) {
      element.scrollTop = position;
    }
  };

  const patchSelect = async () => {
    if (selectList.length >= limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }

    const res = (await openDialog(PatchSelectDialog, {
      ids: selectList.map(v => v.taobaoIid),
      limit
    })) as {
      itemList: EsItem[];
      repeatIds: string[];
      repeatItemId: string[];
      notFoundTaobaoIids: number;
    };
    if (!res) {
      return;
    }

    const { itemList, repeatIds, repeatItemId, notFoundTaobaoIids } = res;
    addItems(itemList);
    openDialog(tipDialog, {
      successNum: itemList.length,
      repeatIds,
      repeatItemId,
      notFoundTaobaoIids
    });
  };

  const onSearch = useDebounceFn(async () => {
    isSearching.current = true;
    const values = new Set(searchValue.split('\n').filter(v => v));
    if (values.size > 50) {
      const isUseBatchItem = await Modal.confirm({
        title: '温馨提示',
        content:
          '只支持搜索50个以内的商品，需要批量选择更多商品时，请使用 “批量选择商品”',
        okText: '批量选择商品'
      });
      if (isUseBatchItem) {
        patchSelect();
      }
      return;
    }
    setLoading(true);
    scrollTo(0);
    try {
      await fetchList({ page: 1 });
    } catch (e) {
      warnError(e);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        await initial({ modalAction, selectRecommendItem });
        if (!items.length && ids.length) {
          getSelectItems(ids);
        }
      } catch (e) {
        warnError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [ids]);

  const onSelect = useCallback(
    (item: EsItem) => {
      if (taobaoIids.has(item.taobaoIid)) {
        removeItem(item.taobaoIid);
        return;
      }
      if (
        modalAction === 'addRecommendItem' &&
        [...selectList, item].length + selectRecommendItem.length > 6
      ) {
        message.warning(`至多选择${6 - selectRecommendItem.length}个商品`);
        return;
      }
      if (selectList.length >= limit) {
        message.warning(
          `至多选择${limit}个商品${
            !selectLittleItem ? `（已选择${selectList.length}个）` : ''
          }`
        );
        return;
      }
      addItems([item]);
    },
    [selectList]
  );

  // 滚动到底部请求数据
  const onScroll = useDebounceFn(async () => {
    if (isSearching.current) {
      isSearching.current = false;
      return;
    }
    const element = scrollView.current;
    if (!element) {
      return;
    }
    const position = element.scrollTop + element.clientHeight;
    if (element.scrollHeight - position <= SCROLL_TRIGGER_DISTANCE) {
      await fetchList({ page: page + 1 });
    }
  }, 300);

  const addAllItem = useDebounceFn(async () => {
    if (selectList.length > limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }
    const res = await fetchList({ page: 1, size: total });
    const data = res.dataSource;
    const needAddItems = data.filter(item => !taobaoIids.has(item.taobaoIid));
    if (
      modalAction === 'addRecommendItem' &&
      needAddItems.length + selectList.length + selectRecommendItem.length > 6
    ) {
      message.warning(`至多选择${6 - selectRecommendItem.length}个商品`);
      return;
    }
    if (needAddItems.length + selectList.length > limit) {
      // addItems(needAddItems.slice(0, limit - selectList.length));
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
    } else {
      addItems(needAddItems);
    }
  }, 500);

  const changeApproveStatus = (v: ApproveStatus) => {
    handleApproveStatusChange(v);
    scrollTo(0);
  };

  return (
    <>
      <div className={css.search}>
        <div className={css.selectContainer}>
          <div className={css.searchLeft}>
            <Select
              value={searchApproveStatus}
              className={css.select}
              style={{ width: 100 }}
              onChange={changeApproveStatus}
            >
              <Option value="ALL">全部</Option>
              <Option value="ONSALE">出售中</Option>
              <Option value="INSTOCK">仓库中</Option>
            </Select>
          </div>
          <div className={css.searchRight}>
            {isEditing ? (
              <div className={css.textAreaBox}>
                <div className={css.absoluteBox}>
                  <TextArea
                    className={css.textArea}
                    placeholder={placeholder}
                    onBlur={() => setEditing(false)}
                    autoSize={AUTO_SIZE}
                    onChange={setKeyword}
                    autoFocus
                    value={searchValue}
                  />
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  css.keywordView,
                  !searchValue && css.keywordPlaceholder
                )}
                onClick={() => setEditing(true)}
              >
                {searchValue || placeholder}
              </div>
            )}
            <ItemHelpTooltip />
            <Button type="primary" onClick={onSearch} className={css.searchBtn}>
              查询
            </Button>
            {selectLittleItem ? null : (
              <Button
                type="primary"
                onClick={patchSelect}
                className={css.patch}
              >
                批量选择商品
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className={`${css.content} ${css.contentEdit}`}>
        <div className={css.card} style={{ marginRight: 12 }}>
          <div className={css.cardHeader}>
            <div>
              {modalAction === 'addRecommendItem'
                ? `可选商品数量${Math.max(
                    total - selectRecommendItem.length,
                    0
                  )}件（不包含主商品和已关联商品）`
                : `查询结果${total}件`}
            </div>
            {total && !loading && limit !== 1 && !selectLittleItem ? (
              <div className={css.clear} onClick={() => addAllItem()}>
                全部添加
              </div>
            ) : null}
          </div>

          <div className={css.scroll} onScroll={onScroll} ref={scrollView}>
            <Loading loading={loading}>
              {dataSource.length ? (
                dataSource.map(item => (
                  <GoodsItem
                    item={item}
                    type="wait"
                    selected={taobaoIids.has(item.taobaoIid)}
                    key={item.taobaoIid}
                    onSelect={onSelect}
                  />
                ))
              ) : (
                <div className={css.emptyCtn}>
                  <Empty text="暂无更多商品" />
                </div>
              )}
            </Loading>
          </div>
        </div>

        <div className={css.card}>
          <div className={css.cardHeader}>
            <div>
              {modalAction === 'addRecommendItem' ? '新增关联商品' : '已选商品'}
              {selectList?.length}件
            </div>
            {selectList?.length && limit > 1 ? (
              <ItemClearDropdown
                clearItem={clearItem}
                clearItemInStock={clearItemInStock}
              />
            ) : null}
          </div>
          <VirtualDiv
            className={css.scroll}
            dataSource={selectList || []}
            itemHeight={76}
            renderItem={item => (
              <GoodsItem
                item={item as EsItem}
                type="selected"
                key={(item as EsItem).taobaoIid}
                onSelect={() => removeItem((item as EsItem).taobaoIid)}
              />
            )}
          />
        </div>
      </div>
    </>
  );
});
