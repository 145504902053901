import React, { memo, useState } from 'react';
import { Select, Option } from '@/components/widgets/select';
import css from './style.less';
import useSetState from '@/hooks/useSetState';
import { ItemHelpTooltip } from '../itemHelpTooltip';
import { Button } from '@/components/widgets/button';
import classNames from 'classnames';
import { TextArea } from '@/components/widgets/input';
import { ItemSearchQuery } from '@/requests/itemSku/type';

type SearchProps = {
  value: ItemSearchQuery;
  onChange: (v: ItemSearchQuery) => void;
  onSearch: () => void;
};

const placeholder = '支持商品ID/商品标题/商家编码';
const AUTO_SIZE = { minRows: 1, maxRows: 6 };

export const Search = memo((props: SearchProps) => {
  const { value, onChange, onSearch } = props;
  const { searchValue, searchApproveStatus } = value;

  const [edit, setEdit] = useState(false);

  const handleChange = (v: Partial<ItemSearchQuery>) => {
    onChange({ ...value, ...v });
  };

  return (
    <div className={css.search}>
      <div className={css.selectContainer}>
        <div className={css.searchLeft}>
          <Select
            value={searchApproveStatus}
            className={css.select}
            style={{ width: 100 }}
            onChange={v => handleChange({ searchApproveStatus: v })}
          >
            <Option value="ALL">全部</Option>
            <Option value="ONSALE">出售中</Option>
            <Option value="INSTOCK">仓库中</Option>
          </Select>
        </div>
        <div className={css.searchRight}>
          {edit ? (
            <div className={css.textAreaBox}>
              <div className={css.absoluteBox}>
                <TextArea
                  className={css.textArea}
                  placeholder={placeholder}
                  onBlur={() => setEdit(false)}
                  autoSize={AUTO_SIZE}
                  onChange={v => handleChange({ searchValue: v })}
                  autoFocus
                  value={searchValue}
                />
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                css.keywordView,
                !searchValue && css.keywordPlaceholder
              )}
              onClick={() => setEdit(true)}
            >
              {searchValue || placeholder}
            </div>
          )}
          <ItemHelpTooltip />
          <Button type="primary" onClick={onSearch} className={css.searchBtn}>
            查询
          </Button>
        </div>
      </div>
    </div>
  );
});
