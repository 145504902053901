// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index_yanchiImg_hUbzQ {\n  width: 16px;\n  height: 16px;\n  margin: 0 4px;\n}\n.index_text_T7SGn {\n  color: #ccc;\n  margin-left: 4px;\n}\n.index_itemText_zl-0S {\n  display: inline-flex;\n  align-items: center;\n}\n.index_content_sXgqh {\n  display: flex;\n  align-items: center;\n  background-color: #fff;\n  padding: 12px 8px;\n  margin-bottom: 14px;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n.index_content_sXgqh .index_left_ly8G2 {\n  display: flex;\n  flex: none;\n  align-items: center;\n  color: orange;\n  margin-right: 12px;\n}\n.index_content_sXgqh .index_gray_bnl-S {\n  background-color: #eee;\n  padding: 2px 4px;\n  margin-right: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/components/delayIcon/index.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;AACF;AAEA;EACE,WAAA;EACA,gBAAA;AAAF;AAGA;EACE,oBAAA;EACA,mBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,2CAAA;AAFF;AAJA;EAQI,aAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAXA;EAgBI,sBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ","sourcesContent":[".yanchiImg {\n  width: 16px;\n  height: 16px;\n  margin: 0 4px;\n}\n\n.text {\n  color: #ccc;\n  margin-left: 4px;\n}\n\n.itemText {\n  display: inline-flex;\n  align-items: center;\n}\n\n.content {\n  display: flex;\n  align-items: center;\n  background-color: #fff;\n  padding: 12px 8px;\n  margin-bottom: 14px;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n  .left {\n    display: flex;\n    flex: none;\n    align-items: center;\n    color: orange;\n    margin-right: 12px;\n  }\n\n  .gray {\n    background-color: #eee;\n    padding: 2px 4px;\n    margin-right: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yanchiImg": "index_yanchiImg_hUbzQ",
	"text": "index_text_T7SGn",
	"itemText": "index_itemText_zl-0S",
	"content": "index_content_sXgqh",
	"left": "index_left_ly8G2",
	"gray": "index_gray_bnl-S"
};
export default ___CSS_LOADER_EXPORT___;
