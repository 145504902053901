// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_Lnz3K {\n  position: relative;\n  height: 15px;\n}\n.style_bar_j5Pts {\n  position: absolute;\n  height: 15px;\n  border-radius: 6px;\n  min-width: 2px;\n}\n.style_ruler_5Ah2l {\n  height: 28px;\n  overflow: hidden;\n  display: flex;\n  justify-content: space-between;\n}\n.style_rulerItem_plEEf {\n  text-align: center;\n  line-height: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/timeBar/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AAEA;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AAAF;AAGA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;AADF;AAIA;EACE,kBAAA;EACA,iBAAA;AAFF","sourcesContent":[".container {\n  position: relative;\n  height: 15px;\n}\n\n.bar {\n  position: absolute;\n  height: 15px;\n  border-radius: 6px;\n  min-width: 2px;\n}\n\n.ruler {\n  height: 28px;\n  overflow: hidden;\n  display: flex;\n  justify-content: space-between;\n}\n\n.rulerItem {\n  text-align: center;\n  line-height: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_Lnz3K",
	"bar": "style_bar_j5Pts",
	"ruler": "style_ruler_5Ah2l",
	"rulerItem": "style_rulerItem_plEEf"
};
export default ___CSS_LOADER_EXPORT___;
