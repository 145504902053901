// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_success_eDo\\+Y {\n  color: #52c41a;\n}\n.style_error_yGxqe {\n  color: #ff4d4f;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/subAccountPermissions/tipDialog/style.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,cAAA;AAAF","sourcesContent":[".success {\n  color: #52c41a;\n}\n\n.error {\n  color: #ff4d4f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "style_success_eDo+Y",
	"error": "style_error_yGxqe"
};
export default ___CSS_LOADER_EXPORT___;
