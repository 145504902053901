// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_label_RPJ6V {\n  height: 20px;\n  color: #333;\n  font-size: 14px;\n}\n.style_unit_StnIa:not(:last-child) {\n  margin-bottom: 24px;\n}\n.style_input_4dArV {\n  width: 70px;\n  margin: 0 8px;\n}\n.style_select_ziggK {\n  width: 350px;\n}\n.style_tip_gKbkS {\n  margin-left: 70px;\n  margin-top: 12px;\n  color: #999;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalSetting/fatigueDialog/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,WAAA;EACA,aAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,iBAAA;EACA,gBAAA;EACA,WAAA;AAHF","sourcesContent":[".label {\n  height: 20px;\n  color: #333;\n  font-size: 14px;\n}\n\n.unit:not(:last-child) {\n  margin-bottom: 24px;\n}\n\n.input {\n  width: 70px;\n  margin: 0 8px;\n}\n\n.select {\n  width: 350px;\n}\n\n.tip {\n  margin-left: 70px;\n  margin-top: 12px;\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "style_label_RPJ6V",
	"unit": "style_unit_StnIa",
	"input": "style_input_4dArV",
	"select": "style_select_ziggK",
	"tip": "style_tip_gKbkS"
};
export default ___CSS_LOADER_EXPORT___;
