// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_paymentOperatorSelect_6bpM- {\n  min-width: 96px;\n  width: auto;\n  margin-right: 12px;\n}\n.style_paymentNumInput_UPzD1 {\n  width: 100px;\n  margin-right: 8px;\n}\n.style_paymentRangeInput_T\\+Nd1 {\n  width: 105px;\n  margin-right: 8px;\n}\n.style_item_eWGJO {\n  display: flex;\n  padding: 8px 0;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/validPayment/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,kBAAA;AACF;AAEA;EACE,YAAA;EACA,iBAAA;AAAF;AAGA;EACE,YAAA;EACA,iBAAA;AADF;AAIA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;AAFF","sourcesContent":[".paymentOperatorSelect {\n  min-width: 96px;\n  width: auto;\n  margin-right: 12px;\n}\n\n.paymentNumInput {\n  width: 100px;\n  margin-right: 8px;\n}\n\n.paymentRangeInput {\n  width: 105px;\n  margin-right: 8px;\n}\n\n.item {\n  display: flex;\n  padding: 8px 0;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentOperatorSelect": "style_paymentOperatorSelect_6bpM-",
	"paymentNumInput": "style_paymentNumInput_UPzD1",
	"paymentRangeInput": "style_paymentRangeInput_T+Nd1",
	"item": "style_item_eWGJO"
};
export default ___CSS_LOADER_EXPORT___;
