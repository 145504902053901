// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_button_YG58i {\n  margin-left: 4px;\n}\n.style_button_YG58i .style_icon_tbiyd {\n  font-size: 20px;\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/compWrapper/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAFA;EAII,eAAA;EACA,cAAA;AACJ","sourcesContent":[".button {\n  margin-left: 4px;\n\n  .icon {\n    font-size: 20px;\n    color: #999999;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "style_button_YG58i",
	"icon": "style_icon_tbiyd"
};
export default ___CSS_LOADER_EXPORT___;
