import React, { memo, ReactNode, useState, useEffect } from 'react';
import { Button } from '@/components/widgets/button';
import { Upload, Image } from 'antd';
import { warnError } from '@/components/infos';
import { message } from '@/components/widgets/message';
import { imageBlobToBase64 } from '@/utils/image';
import { Icon } from '@/components/widgets/icon';
import css from './style.less';
import { imageDownloadRequest, imageUploadRequest } from '@/libs/http';

type UploadImageProps = {
  width?: number;
  height?: number;
  size?: 'middle' | 'large' | 'small';
  imageId?: number;
  changeImage: (imageId: number) => any;
  removeImage: () => any;
  tips?: ReactNode;
  accept?:string;
};

const allowFileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

export default memo(
  ({
    width = 45,
    height = 45,
    imageId,
    changeImage,
    removeImage,
    size = 'middle',
    tips,
    accept =".png,.jpeg,.jpg,.gif"
  }: UploadImageProps) => {
    const [imageBase64, setImageBase64] = useState('');

    useEffect(() => {
      (async () => {
        try {
          if (!imageBase64 && imageId) {
            const imageBlob = await imageDownloadRequest(imageId);
            if (imageBlob) {
              const base64 = await imageBlobToBase64(imageBlob);
              setImageBase64(base64);
            }
          }
        } catch (e) {
          warnError(e);
        }
      })();
    }, [imageId]);

    const imageUploadAndCacheBase64 = async (file: File) => {
      if (!allowFileType.includes(file.type)) {
        message.warning('图片格式不支持');
        return false;
      }
      if (3 * 1024 * 1024 < file.size) {
        message.warning('图片大小不可超过3M');
        return false;
      }
      try {
        const imageId = await imageUploadRequest(file);
        changeImage(imageId);
      } catch (e) {
        warnError(e);
      }
      return false;
    };
    const handleBeforeUpload = (file: File) => {
      imageUploadAndCacheBase64(file);
      return false;
    };

    const handleImageRemove = () => {
      removeImage();
      setImageBase64('');
    };

    if (imageId && imageBase64) {
      return (
        <div className={css.imgBox}>
          <Image
            src={imageBase64}
            alt="图片"
            width={width}
            height={height}
            preview={{ mask: <Icon type="sousuo" /> }}
          />
          <Icon
            type="shanchu1"
            className={css.delete}
            onClick={handleImageRemove}
          />
        </div>
      );
    }
    return (
      <div className={css.horizon}>
        <Upload
          accept={accept}
          beforeUpload={handleBeforeUpload}
          fileList={[]}
        >
          <Button
            style={{
              borderRadius: 4,
              color: '#666',
              padding: '0 16px'
            }}
            size={size}
          >
            添加图片
          </Button>
        </Upload>
        <div className={css.imageTips}>{tips || '上传图片大小不能超过3M'}</div>
      </div>
    );
  }
);
