import React, { memo } from 'react';
import { HelpTooltip } from '@/components/works/helpTooltip';

export const ItemHelpTooltip = memo(() => (
  <HelpTooltip
    tooltip={
      <>
        <div>1.多个关键词可使用空格连接，表示要求“商品标题”中同时出现</div>
        <div>
          2.支持批量搜索商品ID、商家编码，最多支持同时搜索50个商品，多个请使用换行分隔
        </div>
      </>
    }
  />
));
