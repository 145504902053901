import React, { memo, useEffect, useRef, useState } from 'react';
import { Tooltip } from '../tooltip';
import css from './style.less';
import { AutoTooltipTextProps } from './type';

const AutoTooltipText = memo((props: AutoTooltipTextProps) => {
  const { children, className = '', tooltipContent, taobaoIid } = props;

  const [clientWidth, setClientWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const ref = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (ref && ref.current) {
      setClientWidth(ref.current?.clientWidth || 0);
      setScrollWidth(ref.current?.scrollWidth || 0);
    }
  }, [children]);

  const openItem = () => {
    window.open(`https://item.taobao.com/item.htm?id=${taobaoIid}`);
  };

  return (
    <span ref={ref} className={`${css.autoTooltipText} ${className}`}>
      {scrollWidth > clientWidth ? (
        <Tooltip title={tooltipContent || children}>
          <span className={css.spanWidth}>{children}</span>
        </Tooltip>
      ) : (
        children
      )}
    </span>
  );
});

export default AutoTooltipText;
