import React, { memo, useState } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { OfficeTimeDailogProps, ReissueRadioProps } from './type';
import { FormItem, FormLike, IOS } from '@/components/widgets/formLike';
import css from './style.less';
import { Select } from '@/components/widgets/select';
import { compareTime } from '@/utils/date';
import { FormatTimePicker } from '@/components/widgets/timePicker';
import moment from 'moment';
import { HelpTooltip } from '../components';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import { Spin } from '@/components/widgets/spin';
import { Radio, RadioGroup } from '@/components/widgets/radio';

const TimeHelpTooltip = memo(() => (
  <HelpTooltip
    title={
      <div>
        1.设置上班时间后，可实现非上班时间的任务延迟至上班时间发送
        <br />
        2.若不需要补发，将客服上班时间设置为00:00:00~23:59:59即可
        <br />
        3.推荐设置的上班时间比真实上班时间晚5~10分钟，最大程度保证任务补发成功
      </div>
    }
  />
));

const ReissueRadio = memo((props: ReissueRadioProps) => {
  const { className, ...rest } = props;
  return (
    <RadioGroup className={className} {...rest}>
      <Radio value="DELAY">
        统一延迟至上班时间进行补发
        <HelpTooltip title="可保证发送客服统一" />
      </Radio>
      <Radio value="NO_ONLINE_SUBACCOUNT_DELAY">
        优先由选中的在线客服发出，无在线客服延迟至上班时间进行补发
        <HelpTooltip title="可保证任务及时发出" />
      </Radio>
    </RadioGroup>
  );
});

export const OfficeTimeDailog = memo(
  (props: DialogDeployProps<OfficeTimeDailogProps>) => {
    const { closeDialog, params } = props;
    const { officeTimeConfig, remindTypes, onOk } = params;
    const [formValue, setFormValue] = useState(officeTimeConfig);
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOk = async () => {
      if (!formValue.startTime || !formValue.endTime) {
        message.warning('请选择客服上班时间');
        return;
      }

      setLoad(true);

      try {
        await onOk(formValue);
        message.success('保存成功');
        closeDialog();
      } catch (error) {
        warnError(error);
      } finally {
        setLoad(false);
      }
    };

    const endTimeFromat = (value): string => {
      const { startTime, endTime } = formValue;

      if (compareTime(startTime, endTime)) {
        return `当日${moment(value).format('HH:mm:ss')}`;
      }

      return `次日${moment(value).format('HH:mm:ss')}`;
    };

    return (
      <Dialog
        title="客服上班时间"
        onCancel={closeDialog}
        width={620}
        bodyStyle={{ padding: '24px' }}
        onOk={handleOk}
      >
        <Spin spinning={load}>
          <FormLike value={formValue} onChange={setFormValue}>
            <div className={css.unit}>
              <span className={css.label}>
                客服上班时间
                <TimeHelpTooltip />：
              </span>
              <FormItem name="startTime">
                <FormatTimePicker className={css.input} allowClear={false} />
              </FormItem>
              至
              <FormItem name="endTime">
                <FormatTimePicker
                  className={css.input}
                  format={open ? 'HH:mm:ss' : endTimeFromat}
                  allowClear={false}
                  onOpenChange={setOpen}
                />
              </FormItem>
              <div className={css.tip}>非客服上班时间生成的任务:</div>
              <FormItem name="taskHandleType" io={IOS.valueIO}>
                <ReissueRadio className={css.tip} />
              </FormItem>
            </div>

            <div className={css.unit}>
              <span className={css.label}>补发功能：</span>
              <FormItem name="includeTypes">
                <Select
                  className={css.select}
                  mode="multiple"
                  options={remindTypes.map(val => ({
                    label: val.name,
                    value: val.code
                  }))}
                />
              </FormItem>
            </div>
          </FormLike>
        </Spin>
      </Dialog>
    );
  }
);
