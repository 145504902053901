// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_emojiPop_QEr7O {\n  width: 390px;\n}\n.style_emojiPop_QEr7O .ant-popover-inner-content {\n  padding: 6px;\n}\n.style_popContent_kL0Yp {\n  text-align: center;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n.style_emojiIcon_s9Jbz {\n  height: 24px;\n  width: 24px;\n  margin-right: 5px;\n  cursor: pointer;\n  vertical-align: text-top;\n}\n.style_emojiIcon_s9Jbz.style_disabled_oWS56 {\n  filter: grayscale(100%);\n  cursor: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/emoji/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAFA;EAII,YAAA;AACJ;AAGA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AADF;AAIA;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,wBAAA;AAFF;AAKA;EACE,uBAAA;EACA,YAAA;AAHF","sourcesContent":[".emojiPop {\n  width: 390px;\n\n  :global(.ant-popover-inner-content) {\n    padding: 6px;\n  }\n}\n\n.popContent {\n  text-align: center;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.emojiIcon {\n  height: 24px;\n  width: 24px;\n  margin-right: 5px;\n  cursor: pointer;\n  vertical-align: text-top;\n}\n\n.emojiIcon.disabled {\n  filter: grayscale(100%);\n  cursor: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emojiPop": "style_emojiPop_QEr7O",
	"popContent": "style_popContent_kL0Yp",
	"emojiIcon": "style_emojiIcon_s9Jbz",
	"disabled": "style_disabled_oWS56"
};
export default ___CSS_LOADER_EXPORT___;
