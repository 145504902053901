import { Http, http } from '@/libs/http';
import {
  CreateItemGroupCmd,
  DeleteGroupItemCmd,
  GroupItemDto,
  GroupItemQuery,
  ItemGroupDto,
  ItemGroupPointModifyCmd,
  GroupItemUpdateCmd,
  ModifyItemGroupCmd,
  ItemGroupQuery,
  PointItemGroupsItemsSaveCmd,
  PointItemGroupsItemsAddResultDto,
  ItemGroupRelationWebDto
} from './type';

class ItemGroupRequest {
  private http: Http = http('/api/shop/item-group');

  create(params: CreateItemGroupCmd): Promise<number> {
    return this.http
      .path('/create')
      .setRequestBody({ parentId: 0, ...params })
      .post();
  }

  delete(groupId: number): Promise<void> {
    return this.http.path('/delete').setRequestBody({ groupId }).post();
  }

  deleteGroupItem(params: DeleteGroupItemCmd): Promise<void> {
    return this.http.path('/delete-item').setRequestBody(params).post();
  }

  // 不包含未分组
  list(): Promise<ItemGroupDto[]> {
    return this.http.path('/list').post();
  }

  listItem(params: GroupItemQuery): Promise<GroupItemDto> {
    return this.http.path('/list-item').setRequestBody(params).post();
  }

  update(params: ModifyItemGroupCmd): Promise<void> {
    return this.http.path('/update').setRequestBody(params).post();
  }

  updateGroupItem(params: GroupItemUpdateCmd): Promise<void> {
    return this.http.path('/update-group-item').setRequestBody(params).post();
  }

  updateGroupItemPoint(cmd: ItemGroupPointModifyCmd): Promise<void> {
    return this.http.path('/update-group-order').setRequestBody(cmd).post();
  }

  // 包含未分组
  searchItemProups(params: ItemGroupQuery): Promise<ItemGroupDto[]> {
    return this.http.path('/list-with-ungrouped').setRequestBody(params).post();
  }

  addItemsToPointGroups(
    params: PointItemGroupsItemsSaveCmd
  ): Promise<PointItemGroupsItemsAddResultDto> {
    return this.http
      .path('/add-items-to-point-groups')
      .setRequestBody(params)
      .post();
  }

  checkRelation(groupId: number): Promise<ItemGroupRelationWebDto> {
    return this.http.path('/check-relation').setRequestBody({ groupId }).post();
  }
  
  listAllItems(groupId: number): Promise<GroupItemDto> {
    return this.http.path('/list-all-items').setRequestBody({ groupId }).post();
  }
}

export const itemGroupRequest = new ItemGroupRequest();
