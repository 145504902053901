import { compact } from 'lodash';
import { SubAccountPermissionBatchUpdateCmd } from '@/requests/shop/type';

export const verifyParams = (params: SubAccountPermissionBatchUpdateCmd) => {
  const {
    trackerOrderEnabledOption,
    trackerOrderEnabled,
    traceOrderSelfModifyAllowedOption,
    traceOrderSelfModifyAllowed,
    sendMessageSpeedLevelOption,
    sendMessageSpeedLevel,
    subAccounts
  } = params;

  if (trackerOrderEnabledOption && trackerOrderEnabled === undefined) {
    return '请选择“跟单开关”';
  }

  if (
    traceOrderSelfModifyAllowedOption &&
    traceOrderSelfModifyAllowed === undefined
  ) {
    return '请选择“是否允许客服自己开启/关闭跟单开关”';
  }

  if (sendMessageSpeedLevelOption && sendMessageSpeedLevel === undefined) {
    return '请选择“发送速度”';
  }

  if (!subAccounts.length) {
    return '请填写子账号';
  }

  const dedupPatchList = [...new Set(subAccounts)];
  if (dedupPatchList.length > 200) {
    return '单次最多批量处理200个子账号';
  }
};
