import { useCallback, useState } from 'react';

/**
 * 管理 object 类型 state 的 Hooks，用法与react class 组件的 this.setState 基本一致。
 * https://ahooks.js.org/zh-CN/hooks/state/use-set-state
 * @param initialState
 */
const useSetState = <T>(
  initialState: T | (() => T)
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  const [state, setState] = useState<T>(initialState);

  const setMergeState = useCallback(
    (patch: any) => {
      setState(prevState => ({
        ...prevState,
        ...(patch instanceof Function ? patch(prevState) : patch)
      }));
    },
    [setState]
  );

  return [state, setMergeState];
};

export default useSetState;
