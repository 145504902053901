import { act, flow, strict, Model, avatar } from 'agent-reducer';
import { ApproveStatus } from '@/types/approveStatus';
import { EsItem } from '@/types/esItem';
import { EsItemCommand } from '@/requests/chatpeer/type';
import chatpeerRequest from '@/requests/chatpeer';
import { warnError } from '@/components/infos';
import { noop } from '@/polyfill/func';

type State = {
  selectList: EsItem[];
  dataSource: EsItem[];
  page: number;
  pageSize: number;
  total: number;
  searchValue: string;
  searchApproveStatus: ApproveStatus | undefined;
  isEditing: boolean;
  modalAction: string;
  selectRecommendItem: string[];
};

const filterItems = (
  origin: EsItem[],
  modalAction: string,
  selectRecommendItem: string[]
) =>
  origin.filter(i =>
    modalAction === 'addRecommendItem'
      ? !selectRecommendItem.includes(i.taobaoIid)
      : i
  );

@strict()
export class ItemSelectorModel implements Model<State> {
  state: State = {
    selectList: [],
    dataSource: [],
    page: 1,
    pageSize: 10,
    total: 0,
    searchValue: '',
    searchApproveStatus: 'ONSALE',
    isEditing: false,
    modalAction: '',
    selectRecommendItem: []
  };

  constructor(items) {
    this.state.selectList = items;
  }

  interaction = avatar({
    changeItems(items) {
      noop();
    }
  });

  @act()
  changeState(state: Partial<State>) {
    return { ...this.state, ...state };
  }

  @act()
  setKeyword(searchValue: string): State {
    return { ...this.state, searchValue };
  }

  @act()
  setEditing(editing: boolean): State {
    return { ...this.state, isEditing: editing };
  }

  @act()
  addItems(items: EsItem[]): State {
    const newSelectList = [...new Set([...items, ...this.state.selectList])];

    this.interaction.current.changeItems(newSelectList);
    return { ...this.state, selectList: newSelectList };
  }

  @act()
  removeItem(id: string): State {
    const newSelectList = this.state.selectList.filter(
      item => item.taobaoIid !== id
    );

    this.interaction.current.changeItems(newSelectList);
    return { ...this.state, selectList: newSelectList };
  }

  @act()
  clearItem(): State {
    this.interaction.current.changeItems([]);

    return { ...this.state, selectList: [] };
  }

  @act()
  clearItemInStock(): State {
    const newSelectList = this.state.selectList.filter(
      item => item.approveStatus !== 'instock'
    );

    this.interaction.current.changeItems(newSelectList);
    return { ...this.state, selectList: newSelectList };
  }

  @flow()
  async getSelectItems(ids: string[]) {
    const selectList = await chatpeerRequest.getByTaoBaoIIds({
      searchTaobaoIids: ids
    });

    this.interaction.current.changeItems(selectList);
    this.changeState({ selectList });
  }

  @flow()
  async fetchList({
    page,
    size
  }: {
    page?: number;
    size?: number;
  }): Promise<State> {
    try {
      const {
        pageSize,
        dataSource,
        page: currentPage,
        searchValue,
        searchApproveStatus,
        modalAction,
        selectRecommendItem
      } = this.state;
      const newPage = typeof page === 'number' ? page : currentPage;
      const params: EsItemCommand = {
        searchValue: searchValue ? searchValue.trim() : undefined,
        pageSize: size || pageSize,
        page: newPage,
        searchApproveStatus,
        lastModified:
          page !== 1 && dataSource.length
            ? dataSource[dataSource.length - 1].modified
            : undefined,
        lastTaobaoIid:
          page !== 1 && dataSource.length
            ? dataSource[dataSource.length - 1].taobaoIid
            : undefined
      };

      const { items, totalResult } = await chatpeerRequest.esItemList(params);

      if (page === 1) {
        this.changeState({
          ...this.state,
          dataSource: filterItems(items, modalAction, selectRecommendItem),
          page,
          total: totalResult
        });
        return this.state;
      }

      if (items.length === 0) {
        this.changeState({ ...this.state, total: totalResult });
        return this.state;
      }

      this.changeState({
        ...this.state,
        dataSource: filterItems(
          [...dataSource, ...items],
          modalAction,
          selectRecommendItem
        ),
        page,
        pageSize: size || pageSize,
        total: totalResult,
        searchApproveStatus
      });

      return this.state;
    } catch (error) {
      warnError(error);
    }

    return this.state;
  }

  @flow()
  async initial({
    modalAction,
    selectRecommendItem
  }: {
    modalAction: string;
    selectRecommendItem: string[];
  }): Promise<void> {
    this.changeState({ modalAction, selectRecommendItem });

    await this.fetchList({ page: 1 });
  }

  @flow()
  async handleApproveStatusChange(approveStatus: ApproveStatus) {
    this.changeState({ searchApproveStatus: approveStatus });

    await this.fetchList({ page: 1 });
  }
}
