import { withGlobalDialogs, Modal } from '@/components/widgets/modal';
import React, { memo, ReactElement, useMemo } from 'react';
import { instanceBy } from '@/libs/history';
import { Redirect, Route, Router, Switch } from 'react-router';
import {
  LayoutContainer,
  LeftContainer,
  RightContainer
} from '@/components/layouts/container';
import {
  findStartPatSelected,
  Menu,
  MenuItem,
  useAvailableMenuUrls,
  useMenuFilter
} from '@/entry/menu';
import { Header } from '@/entry/header';
import routes from '@/routeConfig';
import { RedirectTo } from '@/components/layouts/route';
import { PageContent } from '@/entry/pageContent';
import { uiPrompt } from '@/avatars';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import { DemoStore } from '@/components/works/demoStore';
import { Advertisement } from '@/components/works/advertisement';
import PageRoutes from '@/pages';
import { shopChildNodes } from '@/pages/childNodes';

uiPrompt.implement({
  success(msg) {
    message.success(msg);
  },
  error(msg) {
    warnError(msg);
  },
  warning(msg) {
    message.warning(msg);
  },
  modalError(msg) {
    return Modal.error(msg);
  },
  modalConfirm(msg) {
    return Modal.confirm(msg);
  },
  modalWarn(msg) {
    return Modal.warn(msg);
  },
  expireModalConfirm(msg) {
    return Modal.warn({
      ...msg,
      content: (
        <span>
          {msg.content}
          <span style={{ color: '#ff8600' }}>{msg.subContent}</span>
        </span>
      )
    });
  }
});

const historyMode = process.env.history;

const history = instanceBy({ mode: historyMode as 'h5' | 'hash' });

const DEFAULT_MAIN_ACCOUNT_REDIRECT: RedirectTo = {
  pathname: '/staffManage',
  search: 'pageAuthority=mainAccount'
};

const DISPLAY_LIST = ['strategy', 'reply', 'setting'];

export const renderMenu = (pageAuthority: string) => (
  <>
    {pageAuthority !== 'mainAccount' ? (
      <>
        <Menu
          title="跟单数据"
          type="statistics"
          activeIcon="gendanshuju"
          icon="gendanshuju1"
          defaultSpread={false}
        >
          <MenuItem title="催拍" url={routes.dunningBuyStatistics} />
          <MenuItem title="催付" url={routes.dunningPayStatistics} />
          <MenuItem title="付款关怀" url={routes.paymentPushStatistics} />
          <MenuItem title="发货提醒" url={routes.consignPushStatistics} />
          <MenuItem
            title="物流停滞提醒"
            url={routes.logisticStagnatePushStatistic}
          />
          <MenuItem title="派送提醒" url={routes.deliveryPushStatistics} />
          <MenuItem title="签收提醒" url={routes.signPushStatistics} />
          <MenuItem title="确认收货关怀" url={routes.receivedPushStatistics} />
          <MenuItem title="退款关怀" url={routes.refundPushStatistics} />
          <MenuItem title="换货关怀" url={routes.exchangePushStatistics} />
          <MenuItem
            title="订单取消关怀"
            url={routes.tradeNopaidClosedPushStatistics}
          />
        </Menu>
        <Menu
          title="跟单助手"
          type="strategy"
          activeIcon="gendanzhushou"
          icon="gendanzhushou1"
        >
          <MenuItem title="催拍" url={routes.dunningBuyStrategy} />
          <MenuItem title="催付" url={routes.dunningPayStrategy} />
          <MenuItem title="付款关怀" url={routes.paymentPushStrategy} />
          <MenuItem title="发货提醒" url={routes.consignPushStrategy} />
          <MenuItem
            title="物流停滞提醒"
            url={routes.logisticStagnatePushStrategy}
          />
          <MenuItem title="派送提醒" url={routes.deliveryPushStrategy} />
          <MenuItem title="签收提醒" url={routes.signPushStrategy} />
          <MenuItem title="确认收货关怀" url={routes.receivedPushStrategy} />
          <MenuItem title="退款关怀" url={routes.refundPushStrategy} />
          <MenuItem title="换货关怀" url={routes.exchangePushStrategy} />
          <MenuItem
            title="订单取消关怀"
            url={routes.tradeNopaidClosedPushStrategy}
          />
        </Menu>
        <Menu
          title="接待设置"
          type="receive"
          activeIcon="chaoshihuifu"
          icon="chaoshihuifudefuben"
        >
          <MenuItem title="等待超时回复" url={routes.replyTimeoutPush} />
          <MenuItem title="转接话术" url={routes.transferWordConfig} />
        </Menu>
        <Menu
          title="服务邀评"
          type="invite-rate"
          activeIcon="fuwuyaoping"
          icon="a-fuwuyaoping2"
        >
          <MenuItem title="服务邀评" url={routes.inviteRatestrategy} />
          <MenuItem title="服务邀评数据" url={routes.inviteRateStatistic} />
        </Menu>
        <Menu
          title="营销增收"
          type="marketing"
          activeIcon="yingxiaozengshoudefuben"
          icon="yingxiaozengshou"
        >
          <MenuItem title="买家群管理" url={routes.buyerGroupManage} />
          <MenuItem title="会员营销" url={routes.buyerMemberMarket} />
          <MenuItem title="数据统计" url={routes.buyerMemberMarketStats} />
        </Menu>
        <Menu
          title="快捷通知"
          type="quickNotification"
          activeIcon="kuaijietongzhi"
          icon="kuaijietongzhidefuben"
        >
          <MenuItem title="指定旺旺群发" url={routes.memberMarketing} />
          <MenuItem title="指定订单号群发" url={routes.tradeBatchPush} />
        </Menu>
        <Menu
          title="商品推荐"
          type="itemRecommend"
          activeIcon="a-shangpintuijianmianxing"
          icon="a-shangpintuijianxianxing"
        >
          <MenuItem title="商品搭配" url={routes.itemMatch} />
          <MenuItem title="商品推荐配置" url={routes.itemConfig} />
          <MenuItem title="商品推荐数据" url={routes.itemStatistics} />
        </Menu>
        <Menu
          title="系统设置"
          type="setting"
          activeIcon="xitongshezhi"
          icon="xitongshezhi1"
        >
          <MenuItem title="商品组管理" url={routes.itemGroupManage} />
          <MenuItem title="客服组管理" url={routes.cusTeamManage} />
          <MenuItem title="黑名单管理" url={routes.blacklistManage} />
          <MenuItem title="子账号权限" url={routes.subAccountPermissions} />
          <MenuItem
            title="子账号在线情况"
            url={routes.subAccountOnlineStatus}
          />
          <MenuItem title="通用设置" url={routes.generalSetting} />
          <MenuItem title="操作日志" url={routes.operationLog} />
        </Menu>
      </>
    ) : (
      <>
        <Menu
          title="员工权限"
          type="setting"
          expand={false}
          url={routes.staffManage}
          activeIcon="yuangongguanli1"
          icon="yuangongguanli"
        />

        <Menu
          title="员工在线情况"
          type="realTimeLogin"
          expand={false}
          url={routes.realTimeLogin}
          activeIcon="yuangongguanli1"
          icon="yuangongguanli"
        />
        <Menu
          title="操作日志"
          type="operationsLog"
          expand={false}
          url={routes.subAccounOperationsLog}
          activeIcon="yuangongguanli1"
          icon="yuangongguanli"
        />
      </>
    )}
  </>
);

export default withGlobalDialogs(
  memo(({ to }: { to?: RedirectTo }) => {
    const pageAuthority = window.localStorage.getItem('pageAuthority') || '';

    const menu = useMemo(() => {
      const sub = renderMenu(pageAuthority);
      const children = React.Children.toArray(sub.props.children);
      return children[0] as ReactElement;
    }, [pageAuthority]);

    const urls = useAvailableMenuUrls(menu);
    const leftMenu = useMenuFilter(menu, urls);
    const startPath = useMemo(
      () =>
        pageAuthority !== 'mainAccount'
          ? findStartPatSelected(leftMenu, DISPLAY_LIST)
          : DEFAULT_MAIN_ACCOUNT_REDIRECT,
      [pageAuthority, urls, to]
    );

    return (
      <Router history={history}>
        <Switch>
          <Route path="/login">{shopChildNodes}</Route>
          <Route path="/find-password">{shopChildNodes}</Route>
          <Route path="/guide">{shopChildNodes}</Route>
          <LayoutContainer>
            <LeftContainer>{leftMenu}</LeftContainer>
            <RightContainer>
              <Advertisement />
              <Header />
              <PageContent>
                <PageRoutes to={startPath} />
              </PageContent>

              {process.env.NODE_ENV === 'development' ? <DemoStore /> : null}
            </RightContainer>
          </LayoutContainer>
        </Switch>
      </Router>
    );
  })
);

export const Login = withGlobalDialogs(
  memo(() => {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/login">{shopChildNodes}</Route>
          <Route path="/find-password">{shopChildNodes}</Route>
          <Route path="/guide">{shopChildNodes}</Route>
        </Switch>
      </Router>
    );
  })
);
