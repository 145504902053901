import React, { memo, useEffect, useState } from 'react';
import { EsItem } from '@/types/esItem';
import { Icon } from '@/components/widgets/icon';
import chatpeerRequest from '@/requests/chatpeer';
import { Popover } from '@/components/widgets/popover';
import { VirtualDiv } from '@/components/layouts/virtualList';
import { getThumbnail } from '@/utils/image';
import css from './style.less';

type GoodsPopProps = {
  title: string;
  label: string;
  ids: string[];
  className?: string;
};

const redirectToTaoBao = (id: string) => {
  window.open(`https://item.taobao.com/item.htm?id=${id}`);
};

export const NoDataContent = memo(({ noDataText }: { noDataText: string }) => (
  <div style={{ lineHeight: '40px', textAlign: 'center' }}>{noDataText}</div>
));

const ItemInfo = ({ item }: { item: EsItem }) => {
  const { picUrl, taobaoIid, title } = item || {};
  return (
    <div className={css.goodsItem}>
      {picUrl ? (
        <img
          src={getThumbnail(picUrl)}
          className={css.img}
          onClick={() => redirectToTaoBao(taobaoIid)}
        />
      ) : (
        <Icon
          type="tupian"
          className={css.defaultIcon}
          style={{ fontSize: 38 }}
        />
      )}
      <div className={css.title} onClick={() => redirectToTaoBao(taobaoIid)}>
        {title}
      </div>
    </div>
  );
};

const TaobaoItemIdsInfo = memo(({ items }: { items: EsItem[] }) => (
  <VirtualDiv
    className={css.scroll}
    dataSource={items || []}
    itemHeight={64}
    renderItem={item => (
      <ItemInfo item={item as EsItem} key={(item as EsItem).taobaoIid} />
    )}
  />
));

export default memo((props: GoodsPopProps) => {
  const { title = '', label = '', ids = [], className } = props;
  const [items, setItems] = useState<EsItem[]>([]);
  useEffect(() => {
    (async () => {
      const itemList = await chatpeerRequest.getByTaoBaoIIds({
        searchTaobaoIids: ids
      });
      setItems(itemList);
    })();
  }, [ids]);

  if (!items.length) {
    return <span className={className}>{label}</span>;
  }

  return (
    <Popover
      placement="left"
      destroyTooltipOnHide
      overlayClassName={css.pop}
      content={
        <div className={css.goods}>
          <div className={css.goodsTitle}>{title}</div>
          <div className={css.goodsWrap}>
            {items?.length ? (
              <TaobaoItemIdsInfo items={items} />
            ) : (
              <NoDataContent noDataText="未关联商品" />
            )}
          </div>
        </div>
      }
    >
      <span className={className}>{label}</span>
    </Popover>
  );
});
