// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_info_3hzWg {\n  margin: 0 4px;\n}\n.ant-input-number-handler-wrap {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/rangeInputNumber/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,aAAA;AAAF","sourcesContent":[".info {\n  margin: 0 4px;\n}\n\n:global(.ant-input-number-handler-wrap) {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "style_info_3hzWg"
};
export default ___CSS_LOADER_EXPORT___;
