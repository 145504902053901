import { OriginAgent, weakSharing } from 'agent-reducer';
import { GoodsSelectModalProps, State } from './type';
import { EsItem } from '@/types/esItem';
import { SkuWebDto } from '@/requests/itemSku/type';

const getDefaultState = (props: GoodsSelectModalProps): State => ({
  selectItems: props.items ? props.items : [],
  selectSellerCatList: props.sellerCatCids ? props.sellerCatCids : [],
  sellerCatNames: [],
  skus: [],
  selectSkuIds: props.taobaoSkuIds ? props.taobaoSkuIds : [],
  selectItemGroupIds: props.itemGroupIds ? props.itemGroupIds : [],
  itemGroupNames: []
});

export class GoodsSelectModalModel implements OriginAgent<State> {
  state: State;

  constructor(props: GoodsSelectModalProps) {
    this.state = getDefaultState(props);
  }

  handleItemChange(items: EsItem[]) {
    return { ...this.state, selectItems: items };
  }

  handleSellerCatCidsChange(e, checkInfo?: any) {
    return {
      ...this.state,
      selectSellerCatList: e,
      sellerCatNames: checkInfo?.checkedNodes.map(i => i.title)
    };
  }

  handleTaobaoSkuIdsChange(skus: SkuWebDto[]) {
    return { ...this.state, skus, selectSkuIds: skus.map(i => i.taobaoSkuId) };
  }

  handleItemGroupIdsChange(selectItemGroupIds: number[], checkInfo?: any) {
    return {
      ...this.state,
      selectItemGroupIds,
      itemGroupNames: checkInfo?.checkedNodes.map(i => i.title)
    };
  }
}

export const goodsSelectModalModelRef = weakSharing(
  (props: GoodsSelectModalProps) => new GoodsSelectModalModel(props)
);
