import { message } from '@/components/widgets/message';

export async function copy(text: string): Promise<void> {
  const statsu = await navigator.permissions.query({
    name: 'clipboard-write'
  });

  if (statsu.state !== 'granted') {
    message.warning('没有剪切板权限');
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
    message.success('复制成功');
  } catch (error) {
    message.warning('复制失败');
  }
}
