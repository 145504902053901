// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tabWrapper_3BRvW {\n  margin-bottom: 24px;\n}\n.style_tabWrapper_3BRvW .style_unit_yxKW7 {\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/statisticComponents/statisticTabs/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAII,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".tabWrapper {\n  margin-bottom: 24px;\n\n  .unit {\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabWrapper": "style_tabWrapper_3BRvW",
	"unit": "style_unit_yxKW7"
};
export default ___CSS_LOADER_EXPORT___;
