// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_skuWrapper_PAhTj {\n  display: flex;\n  padding: 4px 8px;\n  align-items: center;\n  border-bottom: 1px solid #e6e8ed;\n  background-color: #f7f8fa;\n}\n.style_skuWrapper_PAhTj .style_img_MnBil {\n  width: 36px;\n  height: 36px;\n  min-width: 36px;\n  min-height: 36px;\n  margin-right: 10px;\n  overflow: hidden;\n  border: 1px solid #e6e8ed;\n  border-radius: 4px;\n}\n.style_skuWrapper_PAhTj .style_title_kjHET {\n  color: #666;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/goodsSkuPop/sku/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,gCAAA;EACA,yBAAA;AACF;AANA;EAQI,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAhBA;EAmBI,WAAA;EACA,eAAA;AAAJ","sourcesContent":[".skuWrapper {\n  display: flex;\n  padding: 4px 8px;\n  align-items: center;\n  border-bottom: 1px solid #e6e8ed;\n  background-color: #f7f8fa;\n\n  .img {\n    width: 36px;\n    height: 36px;\n    min-width: 36px;\n    min-height: 36px;\n    margin-right: 10px;\n    overflow: hidden;\n    border: 1px solid #e6e8ed;\n    border-radius: 4px;\n  }\n\n  .title {\n    color: #666;\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skuWrapper": "style_skuWrapper_PAhTj",
	"img": "style_img_MnBil",
	"title": "style_title_kjHET"
};
export default ___CSS_LOADER_EXPORT___;
