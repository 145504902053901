// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_content_WCUuO {\n  max-width: 300px;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/customPop/sellerTradePop/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACF","sourcesContent":[".content {\n  max-width: 300px;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "style_content_WCUuO"
};
export default ___CSS_LOADER_EXPORT___;
