import { ItemFilterConfig } from '@/requests/remindStrategy/types';

export const validateItemConfig = (config?: ItemFilterConfig) => {
  const {
    relatedType,
    relatedTaobaoIids,
    itemGroupIds,
    relatedTaobaoSkuIds,
    sellerCatCids
  } = config || {};

  if (!relatedType || relatedType === 'NONE') {
    return '请选择商品';
  }

  if (relatedType === 'ITEM' && !relatedTaobaoIids?.length) {
    return '请选择商品';
  }

  if (relatedType === 'ITEM_GROUP' && !itemGroupIds?.length) {
    return '请选择商品组';
  }

  if (relatedType === 'SKU' && !relatedTaobaoSkuIds?.length) {
    return '请选择SKU';
  }

  if (relatedType === 'SELLER_CAT' && !sellerCatCids?.length) {
    return '请选择店铺分类';
  }
};
