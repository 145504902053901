import React, { useState } from 'react';
import { Dialog } from '@/components/widgets/modal';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { RadioGroup, Radio } from '@/components/widgets/radio';
import { FormLike } from '@/components/widgets/formLike';
import { SellerFlags } from '@/components/works/sellerFlags';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { SubStrategySellerTradeMemoConfig } from '@/requests/remindStrategy/types';
import css from './style.less';
import { TagSelect } from '@/components/works/tagSelect';
import { Alert } from '@/components/layouts/alert';
import classNames from 'classnames';
import { validSellerTradeMemoConfig } from '../index';
import { message } from '@/components/widgets/message';
import { CopyAndResetWrapper } from '@/components/works/compWrapper';
import { copy } from '@/utils/copy';
import { noop } from '@/polyfill/func';

type SellerTradeDialogProps = { value?: SubStrategySellerTradeMemoConfig };

export const SellerTradeDialog = (
  props: DialogDeployProps<SellerTradeDialogProps>
) => {
  const {
    closeDialog,
    params: { value }
  } = props;

  const [type, setType] = useState(value ? 'POINT' : 'ALL');
  const [form, setForm] = useState({ ...value });

  const hiddenSelector = type === 'ALL';

  const handleFormChange = v => {
    setForm(v);
  };

  const handleReset = v => {
    setForm({ ...form, ...v });
  };

  const handleOk = () => {
    if (type === 'ALL') {
      closeDialog(null);
      return;
    }

    const isValidSellerTradeMemoConfig = validSellerTradeMemoConfig(
      form as SubStrategySellerTradeMemoConfig
    );

    if (isValidSellerTradeMemoConfig) {
      message.warning(isValidSellerTradeMemoConfig);
      return;
    }

    closeDialog({ ...form });
  };

  return (
    <Dialog
      title="订单备注(卖家)"
      width={650}
      onCancel={() => closeDialog()}
      onOk={handleOk}
    >
      <div className={css.item}>
        订单备注：
        <RadioGroup value={type} onChange={e => setType(e.target.value)}>
          <Radio value="ALL">不限</Radio>
          <Radio value="POINT">指定订单备注</Radio>
        </RadioGroup>
      </div>

      <div
        className={classNames(hiddenSelector ? css.noLimitedWrap : css.hidden)}
      >
        <div>已选择“不限”</div>
      </div>

      <div className={classNames(hiddenSelector ? css.hidden : '')}>
        <Alert>旗帜和包含/不包含关键词为且的关系，订单备注需同时满足</Alert>

        <FormLike value={form} onChange={handleFormChange}>
          <ConditionUnit
            label="备注旗帜"
            name="filterFlags"
            className={css.item}
          >
            <SellerFlags />
          </ConditionUnit>
          <ConditionUnit
            label="备注包含关键词"
            name="filterKeywords"
            className={css.item}
          >
            <CopyAndResetWrapper
              onCopy={() => {
                copy(form?.filterKeywords?.join(',') || '');
              }}
              onReset={() => handleReset({ filterKeywords: [] })}
            >
              <TagSelect
                className={css.select}
                maxLength={20}
                showCount
                tokenSeparators={[',', '，']}
              />
            </CopyAndResetWrapper>
          </ConditionUnit>
          <ConditionUnit
            label="备注不包含关键词"
            name="rejectKeywords"
            className={css.item}
          >
            <CopyAndResetWrapper
              onCopy={() => copy(form?.rejectKeywords?.join(',') || '')}
              onReset={() => handleReset({ rejectKeywords: [] })}
            >
              <TagSelect
                className={css.select}
                maxLength={20}
                showCount
                tokenSeparators={[',', '，']}
              />
            </CopyAndResetWrapper>
          </ConditionUnit>
        </FormLike>
      </div>
    </Dialog>
  );
};
