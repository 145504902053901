import React from 'react';
import { Select, SelectProps } from '@/components/widgets/select';

export function TagSelect(props: SelectProps): JSX.Element {
  const { onChange, mode = 'tags', maxLength, ...rest } = props;

  const handleChange: SelectProps['onChange'] = (tags: string[], options) => {
    onChange?.(
      tags?.map(item => item.trim()).filter(item => item.length),
      options
    );
  };

  return (
    <Select
      mode={mode}
      open={false}
      maxLength={maxLength}
      onChange={handleChange}
      {...rest}
    />
  );
}
