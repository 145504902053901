import React, { memo, useEffect, useState } from 'react';
import { buildQuickDateRangeFromToday } from '@/utils/date';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import commonCss from '@/css/common.less';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import { warnError } from '@/components/infos';
import { Select, Option } from '@/components/widgets/select';
import { AccountSelect } from '../../accountSelector';
import { Button } from '@/components/widgets/button';
import { Input } from '@/components/widgets/input';
import { InputProps } from '@/components/widgets/input/type';
import shopRequest from '@/requests/shop';
import { RecordSearchProps } from './type';
import { RemindSubStrategyIdAndNameWebDto } from '@/requests/shop/type';
import { RefundClosedSelect } from '../detailSearch';

const CustomNumberInput = (props: InputProps) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!e) {
      return;
    }

    const numberValue =
      typeof e.target?.value === 'string'
        ? e.target?.value.replace(/\D+/g, '')
        : '';

    props.onChange?.(numberValue, e);
  };

  return <Input onBlur={handleBlur} {...props} />;
};

export const RecordSearch = memo((props: RecordSearchProps) => {
  const { items, dataSource, onChange, onSearch, onReset, remindType } = props;

  const [strategyNameList, setStrategyNameList] = useState<
    RemindSubStrategyIdAndNameWebDto[]
  >([]);

  const fetchStrategyNameList = async () => {
    if (!remindType) {
      return;
    }
    try {
      const data = await shopRequest.getStrategyNameList(remindType);
      setStrategyNameList(data);
    } catch (err) {
      warnError(err);
      setStrategyNameList([]);
    }
  };

  useEffect(() => {
    fetchStrategyNameList();
  }, []);

  return (
    <FormLike value={dataSource} onChange={onChange} onSubmit={onChange}>
      <div className={commonCss.search}>
        {items.map(item => {
          const { slot, dataIndex, title } = item;
          if (slot === 'dateRange') {
            return (
              <ConditionUnit
                key={dataIndex}
                name={dataIndex}
                className={commonCss.item}
                label={title ?? ''}
              >
                <CustomRangePicker options={buildQuickDateRangeFromToday()} />
              </ConditionUnit>
            );
          }
          if (slot === 'sendNum') {
            return (
              <ConditionUnit
                key={dataIndex}
                name={dataIndex}
                label={title ?? '发送次数'}
                className={commonCss.item}
              >
                <Select style={{ width: '180px' }}>
                  <Option value={null}>全部</Option>
                  {remindType === 'REMIND_FINAL_PAY' ? (
                    <>
                      <Option value="FIRST_REMIND">付尾款开始前</Option>
                      <Option value="SECOND_REMIND">付尾款开始后</Option>
                      <Option value="THIRD_REMIND">付尾款结束前</Option>
                    </>
                  ) : (
                    <>
                      <Option value="FIRST_REMIND">第一次</Option>
                      <Option value="SECOND_REMIND">第二次</Option>
                    </>
                  )}
                </Select>
              </ConditionUnit>
            );
          }
          if (slot === 'subStrategy') {
            return (
              <ConditionUnit
                key={dataIndex}
                name={dataIndex}
                label={title ?? '任务名称'}
                className={commonCss.item}
              >
                <Select
                  style={{ width: '260px' }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                >
                  {strategyNameList.map(v => (
                    <Option key={v.id} value={v.id}>
                      {v.strategyName}
                    </Option>
                  ))}
                </Select>
              </ConditionUnit>
            );
          }
          if (slot === 'subAccount') {
            return (
              <ConditionUnit
                key={dataIndex}
                name={dataIndex}
                label={title ?? '客服'}
                className={commonCss.item}
              >
                <AccountSelect
                  singleSelect
                  style={{ width: 180 }}
                  allowClear
                  showAll
                />
              </ConditionUnit>
            );
          }
          if (slot === 'buyerReplied') {
            return (
              <ConditionUnit
                name="buyerReplied"
                label="买家是否回复"
                className={commonCss.item}
              >
                <RefundClosedSelect />
              </ConditionUnit>
            );
          }
          if (slot === 'taobaoTrade') {
            return (
              <ConditionUnit
                key={dataIndex}
                name={dataIndex}
                label={title ?? '订单号'}
                className={commonCss.item}
              >
                <CustomNumberInput style={{ width: 180 }} />
              </ConditionUnit>
            );
          }
          return null;
        })}
        <div className={commonCss.item}>
          <Button type="primary" onClick={onSearch}>
            搜索
          </Button>
          <Button onClick={onReset}>重置</Button>
        </div>
      </div>
    </FormLike>
  );
}) as <T extends Record<string, any>>(
  props: RecordSearchProps<T>
) => JSX.Element;
