// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_rIjFq {\n  background-color: #fff;\n  padding: 24px;\n  border-radius: 5px;\n}\n.style_container_rIjFq:not(:last-child) {\n  margin-bottom: 24px;\n}\n.style_container_rIjFq .style_title_yGejR {\n  font-weight: bold;\n  font-size: 15px;\n}\n.style_searcher_IqKqW {\n  display: flex;\n  align-items: center;\n  color: #333333;\n  font-size: 14px;\n  margin: 16px 0 32px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/realTimeLogin/components/style.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAPA;EAUI,iBAAA;EACA,eAAA;AAAJ;AAIA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AAFF","sourcesContent":[".container {\n  background-color: #fff;\n  padding: 24px;\n  border-radius: 5px;\n\n  &:not(:last-child) {\n    margin-bottom: 24px;\n  }\n\n  .title {\n    font-weight: bold;\n    font-size: 15px;\n  }\n}\n\n.searcher {\n  display: flex;\n  align-items: center;\n  color: #333333;\n  font-size: 14px;\n  margin: 16px 0 32px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_rIjFq",
	"title": "style_title_yGejR",
	"searcher": "style_searcher_IqKqW"
};
export default ___CSS_LOADER_EXPORT___;
