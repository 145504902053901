import React, { memo, useEffect, useState } from 'react';
import { Icon } from '@/components/widgets/icon';
import { Popover } from '@/components/widgets/popover';
import { VirtualDiv } from '@/components/layouts/virtualList';
import { itemSkuRequest } from '@/requests/itemSku';
import { ItemWithSkusWebDto } from '@/requests/itemSku/type';
import { Item } from './item';
import { Sku } from './sku';
import css from './style.less';

type GoodsSkuPopProps = {
  title: (v: number) => string;
  label: (v: number) => string;
  taobaoSkuIds: string[];
  className?: string;
};

const ItemWithSkuInfo = memo(
  ({ itemWithSkus }: { itemWithSkus: ItemWithSkusWebDto[] }) => (
    <div>
      {itemWithSkus.map(itemWithSku => {
        return (
          <Item item={itemWithSku} key={itemWithSku.taobaoIid}>
            {itemWithSku.skuList.map(sku => {
              return (
                <Sku
                  sku={sku}
                  skuPicUrl={itemWithSku.picUrl}
                  key={sku.taobaoSkuId}
                />
              );
            })}
          </Item>
        );
      })}
    </div>
  )
);

export const GoodsSkuPop = memo((props: GoodsSkuPopProps) => {
  const { title, label, taobaoSkuIds = [], className } = props;

  const [itemWithSkus, setItemWithSkus] = useState<ItemWithSkusWebDto[]>([]);
  const [skuNum, setSkuNum] = useState(taobaoSkuIds.length);

  useEffect(() => {
    (async () => {
      const data = await itemSkuRequest.itemSkuDetails(taobaoSkuIds);

      setSkuNum(data.reduce((pre, cur) => pre + cur.skuList.length, 0));
      setItemWithSkus(data);
    })();
  }, [taobaoSkuIds]);

  if (!itemWithSkus.length) {
    return <span className={className}>{label(skuNum)}</span>;
  }

  return (
    <Popover
      placement="left"
      destroyTooltipOnHide
      overlayClassName={css.pop}
      content={
        <div className={css.goods}>
          <div className={css.goodsTitle}>{title(skuNum)}</div>
          <div className={css.goodsWrap}>
            <ItemWithSkuInfo itemWithSkus={itemWithSkus} />
          </div>
        </div>
      }
    >
      <span className={className}>{label(skuNum)}</span>
    </Popover>
  );
});
