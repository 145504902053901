import { Spin } from 'antd';
import { isNumber } from 'lodash';
import React, { memo } from 'react';
import { LoadingProps } from './type';

const Loading = memo((props: LoadingProps) => {
  const { size = 'default', height = '100%', loading, children } = props;

  const style = {
    height: isNumber(height) ? `${height}px` : height,
    display: 'flex',
    justifyContent: 'center'
  };
  return (
    <>
      {loading ? (
        <div style={style}>
          <div style={{ alignSelf: 'center' }}>
            <Spin size={size} />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
});

export default Loading;
