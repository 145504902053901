// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_ruleDialog_vFirz .style_desc_\\+s6oQ {\n  margin-bottom: 12px;\n}\n.style_ruleDialog_vFirz .style_item_Cn-cK {\n  display: flex;\n  line-height: 32px;\n}\n.style_ruleDialog_vFirz .style_item_Cn-cK:first-child {\n  margin-bottom: 12px;\n}\n.style_ruleDialog_vFirz .style_item_Cn-cK .style_flexWidth_By4yp {\n  flex: 1;\n}\n.style_ruleDialog_vFirz .style_item_Cn-cK .style_labelText_ceuaT {\n  width: 70px;\n  text-align: right;\n}\n.style_ruleDialog_vFirz .style_item_Cn-cK .style_title_mJ9yz {\n  background: #f7f8fa;\n  color: #999999;\n  line-height: 24px;\n  padding: 6px 12px;\n  margin-bottom: 12px;\n}\n.style_ruleDialog_vFirz .style_selectWidth_rA3rK {\n  width: 72px;\n  margin: 0 4px;\n}\n.style_ruleDialog_vFirz .style_checkbox_yPC76 {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/demoStore/mainStrategy/style.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAFA;EAKI,aAAA;EACA,iBAAA;AAAJ;AACI;EACE,mBAAA;AACN;AATA;EAWM,OAAA;AACN;AAZA;EAcM,WAAA;EACA,iBAAA;AACN;AAhBA;EAkBM,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AACN;AAvBA;EA0BI,WAAA;EACA,aAAA;AAAJ;AA3BA;EA8BI,iBAAA;AAAJ","sourcesContent":[".ruleDialog {\n  .desc {\n    margin-bottom: 12px;\n  }\n  .item {\n    display: flex;\n    line-height: 32px;\n    &:first-child {\n      margin-bottom: 12px;\n    }\n    .flexWidth {\n      flex: 1;\n    }\n    .labelText {\n      width: 70px;\n      text-align: right;\n    }\n    .title {\n      background: #f7f8fa;\n      color: #999999;\n      line-height: 24px;\n      padding: 6px 12px;\n      margin-bottom: 12px;\n    }\n  }\n  .selectWidth {\n    width: 72px;\n    margin: 0 4px;\n  }\n  .checkbox {\n    margin-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ruleDialog": "style_ruleDialog_vFirz",
	"desc": "style_desc_+s6oQ",
	"item": "style_item_Cn-cK",
	"flexWidth": "style_flexWidth_By4yp",
	"labelText": "style_labelText_ceuaT",
	"title": "style_title_mJ9yz",
	"selectWidth": "style_selectWidth_rA3rK",
	"checkbox": "style_checkbox_yPC76"
};
export default ___CSS_LOADER_EXPORT___;
