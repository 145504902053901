import React, { memo } from 'react';
import { Checkbox } from '@/components/widgets/checkbox';
import { InputNumber } from '@/components/widgets/input';
import {
  ExcludePaidItemFilterConfig,
  ItemFilterConfig
} from '@/requests/remindStrategy/types';
import { ItemSelector } from '../itemSelector';
import css from './style.less';
import { RemindType } from '@/types/remindType';
import classNames from 'classnames';

type ExcludePaidItemSelectorProps = {
  remindType: RemindType;
  value?: ExcludePaidItemFilterConfig;
  limitDayError?: string;
  onChange?: (value: ExcludePaidItemFilterConfig) => void;
  cancelFormError?: () => void;
};

const defaultExcludePaidItemFilterConfig: ExcludePaidItemFilterConfig = {
  enable: false,
  limitDay: 30,
  itemFilterConfig: {
    relatedType: 'ITEM',
    relatedTaobaoIids: []
  }
};

export const ExcludePaidItemSelector = memo(
  (props: ExcludePaidItemSelectorProps) => {
    const {
      remindType,
      value = defaultExcludePaidItemFilterConfig,
      limitDayError,
      onChange,
      cancelFormError
    } = props;
    const {
      enable,
      limitDay = 30,
      itemFilterConfig = {
        relatedType: 'ITEM',
        relatedTaobaoIids: []
      }
    } = value;

    const triggerChange = (
      changedValue: Partial<ExcludePaidItemFilterConfig>
    ) => {
      onChange?.({
        ...defaultExcludePaidItemFilterConfig,
        ...value,
        ...changedValue
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleItemChange = (itemFilterConfig: ItemFilterConfig) => {
      triggerChange({ itemFilterConfig });
    };

    return (
      <>
        <Checkbox
          checked={enable}
          onChange={e => triggerChange({ enable: e.target.checked })}
        />
        <span className={css.prefix}>购买指定商品的买家</span>
        {enable ? (
          <>
            近
            <InputNumber
              className={classNames(
                css.selectWidth,
                limitDayError ? css.errorBorder : ''
              )}
              onFocus={cancelFormError}
              min={1}
              max={90}
              value={limitDay}
              onChange={v => {
                triggerChange({ limitDay: v as number });
              }}
            />
            天，购买以下任一商品：
            <ItemSelector
              require
              label="选择商品"
              remindType={remindType}
              value={itemFilterConfig}
              onChange={handleItemChange}
            />
          </>
        ) : null}
      </>
    );
  }
);
