import React, { memo } from 'react';
import classNames from 'classnames';
import AutoTooltipText from '@/components/widgets/autoTooltipText';
import { Icon } from '@/components/widgets/icon';
import { EsItem } from '@/types/esItem';
import { getThumbnail } from '@/utils/image';
import css from './style.less';

type GoodsItemProps = {
  type?: 'wait' | 'selected';
  selected?: boolean;
  item: EsItem;
  onSelect?(item: EsItem): void;
};

const approveStatusMap = {
  onsale: '出售中',
  instock: '仓库中'
};

export const linkToGoodsDetail = (goodsId: string) => {
  window.open(`https://item.taobao.com/item.htm?id=${goodsId}`);
};

export const GoodsItem = memo((props: GoodsItemProps) => {
  const {
    picUrl,
    title,
    taobaoIid,
    outerIid,
    approveStatus = 'onsale'
  } = props.item;
  const clickHandle = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    linkToGoodsDetail(id);
  };
  const handleClick = () => {
    if (props.onSelect !== null && props.onSelect !== undefined) {
      props.onSelect(props.item);
    }
  };

  const buildIcon = () => {
    if (props.type) {
      return props.type === 'wait' ? (
        <Icon
          type={`${
            props.selected ? 'duoxuan-xuanzhong' : 'duoxuan-weixuanzhong'
          }`}
          className={classNames(
            css.selectedIcon,
            props.selected ? css.selectedIconActive : css.selectedIconHover
          )}
        />
      ) : (
        <Icon type="duoxuan-yichu" className={css.deleteIcon} />
      );
    }
    return null;
  };

  const buildImageWithStatus = () => (
    <div className={css.imgBox}>
      {picUrl ? (
        <img
          src={getThumbnail(picUrl)}
          className={css.img}
          onClick={e => {
            clickHandle(e, taobaoIid);
          }}
        />
      ) : (
        <Icon
          type="tupian"
          className={css.defaultIcon}
          style={{ fontSize: 38 }}
        />
      )}
      <div className={css.approveStatusMask}>
        {approveStatusMap[approveStatus]}
      </div>
    </div>
  );

  return (
    <div
      className={`${css.option} ${props.selected ? css.selected : ''}`}
      onClick={handleClick}
    >
      {buildImageWithStatus()}
      <div className={css.texts}>
        <div className={css.name}>
          <AutoTooltipText className={css.nameText} taobaoIid={taobaoIid}>
            <span
              className={css.nameLink}
              onClick={e => {
                clickHandle(e, taobaoIid);
              }}
            >
              {title}
            </span>
          </AutoTooltipText>
        </div>
        <div className={css.code}>
          <span className={css.iid}>{`商品ID ${taobaoIid}`}</span>
          {outerIid ? (
            <AutoTooltipText className={css.outerIid} tooltipContent={outerIid}>
              {`商家编码 ${outerIid}`}
            </AutoTooltipText>
          ) : null}
        </div>
      </div>
      {buildIcon()}
    </div>
  );
});
