// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_unit_2TVBr {\n  display: flex;\n}\n.style_label_9DiOV {\n  min-width: 90px;\n  display: flex;\n  justify-content: end;\n}\n.style_inputWidth_qihvV {\n  width: 350px;\n}\n.style_inputWidth_qihvV .ant-input-group-addon {\n  padding: 0;\n}\n.style_btnReset_zBFtT {\n  border: none;\n  background: none;\n  height: 28px;\n}\n.style_shopPermissionConfigurator_VrkXA {\n  display: flex;\n  flex-direction: column;\n}\n.style_shopPermissionConfigurator_VrkXA .style_permissionShop_rwN7d {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/staffManage/resetPasswordDailog/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAGA;EACE,eAAA;EACA,aAAA;EACA,oBAAA;AADF;AAIA;EACE,YAAA;AAFF;AACA;EAKM,UAAA;AAHN;AAQA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;AANF;AASA;EACE,aAAA;EACA,sBAAA;AAPF;AAKA;EAKI,aAAA;AAPJ","sourcesContent":[".unit {\n  display: flex;\n  // align-items: center;\n}\n\n.label {\n  min-width: 90px;\n  display: flex;\n  justify-content: end;\n}\n\n.inputWidth {\n  width: 350px;\n\n  :global {\n    .ant-input-group-addon {\n      padding: 0;\n    }\n  }\n}\n\n.btnReset {\n  border: none;\n  background: none;\n  height: 28px;\n}\n\n.shopPermissionConfigurator {\n  display: flex;\n  flex-direction: column;\n\n  .permissionShop {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit": "style_unit_2TVBr",
	"label": "style_label_9DiOV",
	"inputWidth": "style_inputWidth_qihvV",
	"btnReset": "style_btnReset_zBFtT",
	"shopPermissionConfigurator": "style_shopPermissionConfigurator_VrkXA",
	"permissionShop": "style_permissionShop_rwN7d"
};
export default ___CSS_LOADER_EXPORT___;
