import React, { memo, useEffect, useRef, useState } from 'react';
import css from './style.less';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { push, replace } from '@/libs/history';
import routeConfig from '@/routeConfig';
import webApi from '@/requests/webApi';
import useRequest from '@/hooks/useRequest';
import { BrowserLoginParams } from '@/requests/webApi/type';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import { check, trimObject } from './service';
import { setItem } from '@/utils/storage';
import useDebounceFn from '@/hooks/useDebounceFn';

export default memo(() => {
  document.title = '账号登录 - 智能跟单助手';

  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [realCaptcha, setRealCaptcha] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  const { run: getCaptcha } = useRequest(() => webApi.getCaptcha(), {
    debounceWait: 300,
    onSuccess: res => {
      setRealCaptcha(res);
    },
    onError: err => {
      warnError(err);
    }
  });

  const { run: login, loading } = useRequest(
    (params: BrowserLoginParams) => webApi.browserLogin(params),
    {
      manual: true,
      onSuccess: () => {
        setItem('clientType', 'web');
        window.location.pathname = routeConfig.guide.path;
        // replace();
        message.success('登录成功');
      },
      onError: error => {
        warnError(error);
        getCaptcha();
      }
    }
  );

  const goToFindPassword = () => {
    push(routeConfig.findPassword.path);
  };

  const handleLogin = () => {
    const params = {
      accountName,
      password,
      captchaCode: captcha
    };
    const info = check(trimObject(params, ['password']));

    if (info) {
      message.warning(info);
      return;
    }

    login(trimObject(params, ['password']));
  };

  const refreshCaptcha = () => {
    getCaptcha();
  };

  const enterCallback = useDebounceFn((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('keyup', enterCallback);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keyup', enterCallback);
      }
    };
  }, [enterCallback]);

  return (
    <div className={css.loginWrapper}>
      <div className={css.login}>
        <div className={css.loginLeft}>
          <span>智能跟单助手</span>
          <span>管理后台</span>
        </div>
        <div className={css.loginRight} ref={ref}>
          <h1 style={{ textAlign: 'center' }}>账号登录</h1>
          <Input
            value={accountName}
            onChange={setAccountName}
            className={css.item}
            size="large"
            placeholder="请输入登录账号"
          />
          <Input
            value={password}
            onChange={setPassword}
            className={css.item}
            size="large"
            type="password"
            placeholder="请输入登录密码"
          />
          <div className={css.item}>
            <Input
              size="large"
              placeholder="请输入验证码"
              value={captcha}
              onChange={setCaptcha}
              autoComplete="one-time-code"
            />
            <img src={realCaptcha} alt="" onClick={refreshCaptcha} />
          </div>
          <Button
            loading={loading}
            className={css.item}
            size="large"
            type="primary"
            style={{ margin: '24px 0 0', justifyContent: 'center' }}
            onClick={handleLogin}
          >
            登录
          </Button>
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={goToFindPassword}
          >
            首次登录设置密码/忘记密码？
          </Button>
        </div>
      </div>
    </div>
  );
});
