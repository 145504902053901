// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_pagination_9P1Cj {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n  line-height: 34px;\n}\n.style_pagination_9P1Cj .ant-select-selection-item {\n  overflow: visible;\n}\n.style_text_G4KIR {\n  color: #3d7fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layouts/pager/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AALA;EAMI,iBAAA;AAEJ;AAEA;EACE,cAAA;AAAF","sourcesContent":[".pagination {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n  line-height: 34px;\n  :global(.ant-select-selection-item) {\n    overflow: visible;\n  }\n}\n\n.text {\n  color: #3d7fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": "style_pagination_9P1Cj",
	"text": "style_text_G4KIR"
};
export default ___CSS_LOADER_EXPORT___;
