// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_label_RCEZu {\n  width: 114px;\n  margin-right: 16px;\n  margin-left: 65px;\n  text-align: left;\n  font-weight: 400;\n  color: #999999;\n}\n.style_searchLabel_h5GFz {\n  margin-right: 4px;\n  text-align: left;\n  font-weight: 400;\n  color: #333;\n}\n.style_required_Lt04A::before {\n  display: inline-block;\n  margin-right: 4px;\n  color: #ff4d4f;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  line-height: 1;\n  content: '*';\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/components/conditionUnit/style.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AADF;AAIA;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AAFF;AAKA;EACE,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;EACA,cAAA;EACA,YAAA;AAHF","sourcesContent":["@import '~@/css/color.less';\n\n.label {\n  width: 114px;\n  margin-right: 16px;\n  margin-left: 65px;\n  text-align: left;\n  font-weight: 400;\n  color: #999999;\n}\n\n.searchLabel {\n  margin-right: 4px;\n  text-align: left;\n  font-weight: 400;\n  color: #333;\n}\n\n.required::before {\n  display: inline-block;\n  margin-right: 4px;\n  color: #ff4d4f;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  line-height: 1;\n  content: '*';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "style_label_RCEZu",
	"searchLabel": "style_searchLabel_h5GFz",
	"required": "style_required_Lt04A"
};
export default ___CSS_LOADER_EXPORT___;
