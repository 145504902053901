import { PageDetail } from '@/types/pageDetail';
import { StaffRequest } from '@/requests/staff';
import {
  DelStaffParams,
  EnterpriseStaffWebDto,
  VerifyStaffParams,
  VerifyStaffDto,
  ModifyStaffParams,
  EnterpriseStaffBatchDeleteWebCmd,
  EnterpriseStaffImportResultWebDto,
  EnterpriseStaffPasswordResetWebCmd,
  EnterpriseStaffWebQuery
} from '@/requests/staff/type';
import { PermissionShop } from '@/requests/shop/type';
import { StaffAccountRequest } from '@/requests/staffAccount';
import { AuthorizeConfig } from '@/requests/staffAccount/type';

export class StaffService {
  private staffRequest = new StaffRequest();

  private staffAccountRequest = new StaffAccountRequest();

  deleteStaff(params: DelStaffParams): Promise<boolean> {
    return this.staffRequest.deleteStaff(params);
  }

  batchDeleteStaff(params: EnterpriseStaffBatchDeleteWebCmd): Promise<boolean> {
    return this.staffRequest.batchDeleteStaff(params);
  }

  staffList(
    params: EnterpriseStaffWebQuery
  ): Promise<PageDetail<EnterpriseStaffWebDto>> {
    return this.staffRequest.staffList(params);
  }

  saveStaff(params: ModifyStaffParams): Promise<boolean> {
    return this.staffRequest.saveStaff(params);
  }

  updateStaff(params: ModifyStaffParams): Promise<boolean> {
    return this.staffRequest.updateStaff(params);
  }

  verifyStaff(params: VerifyStaffParams): Promise<VerifyStaffDto> {
    return this.staffRequest.verifyStaff(params);
  }

  listConfigurableShops(): Promise<PermissionShop[]> {
    return this.staffRequest.listConfigurableShops();
  }

  importStaff(params: FormData): Promise<EnterpriseStaffImportResultWebDto> {
    return this.staffRequest.importStaff(params);
  }

  resetPassword(params: EnterpriseStaffPasswordResetWebCmd): Promise<boolean> {
    return this.staffRequest.resetPassword(params);
  }

  getAuthorizeConfig(): Promise<AuthorizeConfig> {
    return this.staffAccountRequest.getAuthorizeConfig();
  }
}

export const staffService = new StaffService();
