// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_label_VyFLG {\n  height: 20px;\n  color: #333;\n  display: inline-block;\n  text-align: right;\n  min-width: 120px;\n  font-size: 14px;\n}\n.style_unit_1OXF-:not(:last-child) {\n  margin-bottom: 24px;\n}\n.style_input_7yCjk {\n  width: 160px;\n  margin: 0 8px;\n}\n.style_select_c\\+Gnq {\n  width: 350px;\n  margin: 0 8px;\n}\n.style_tip_g7-tk {\n  margin-left: 128px;\n  margin-top: 4px;\n  color: #999;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalSetting/officeTimeDailog/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,qBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,YAAA;EACA,aAAA;AADF;AAIA;EACE,YAAA;EACA,aAAA;AAFF;AAKA;EACE,kBAAA;EACA,eAAA;EACA,WAAA;AAHF","sourcesContent":[".label {\n  height: 20px;\n  color: #333;\n  display: inline-block;\n  text-align: right;\n  min-width: 120px;\n  font-size: 14px;\n}\n\n.unit:not(:last-child) {\n  margin-bottom: 24px;\n}\n\n.input {\n  width: 160px;\n  margin: 0 8px;\n}\n\n.select {\n  width: 350px;\n  margin: 0 8px;\n}\n\n.tip {\n  margin-left: 128px;\n  margin-top: 4px;\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "style_label_VyFLG",
	"unit": "style_unit_1OXF-",
	"input": "style_input_7yCjk",
	"select": "style_select_c+Gnq",
	"tip": "style_tip_g7-tk"
};
export default ___CSS_LOADER_EXPORT___;
