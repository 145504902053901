// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_marginRightSm_MewNl {\n  margin-right: 8px;\n}\n.style_cursor_GmGei:hover {\n  cursor: pointer;\n}\n.style_formItem_nqekT {\n  display: flex;\n  align-items: center;\n  margin-right: 12px;\n}\n.style_dateSelector_YX8xx {\n  position: sticky;\n  top: -24px;\n  z-index: 100;\n}\n.style_dateSelector_YX8xx.style_scroll_JoIMK {\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n.style_select_cWiiw {\n  width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/realTimeLogin/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,eAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAIA;EACE,gBAAA;EACA,UAAA;EACA,YAAA;AAFF;AAIE;EACE,0CAAA;AAFJ;AAMA;EACE,YAAA;AAJF","sourcesContent":[".marginRightSm {\n  margin-right: 8px;\n}\n\n.cursor:hover {\n  cursor: pointer;\n}\n\n.formItem {\n  display: flex;\n  align-items: center;\n  margin-right: 12px;\n}\n\n.dateSelector {\n  position: sticky;\n  top: -24px;\n  z-index: 100;\n\n  &.scroll {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  }\n}\n\n.select {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginRightSm": "style_marginRightSm_MewNl",
	"cursor": "style_cursor_GmGei",
	"formItem": "style_formItem_nqekT",
	"dateSelector": "style_dateSelector_YX8xx",
	"scroll": "style_scroll_JoIMK",
	"select": "style_select_cWiiw"
};
export default ___CSS_LOADER_EXPORT___;
