import React, { memo, useEffect } from 'react';
import { Alert } from '@/components/layouts/alert';
import css from './style.less';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { Table, Column } from '@/components/widgets/table';
import { useDialogMethods } from '@/components/widgets/modal';
import { useAgentReducer } from 'use-agent-reducer';
import { staffModelRef } from '@/pages/staffManage/model';
import { warnError } from '@/components/infos';
import { StaffInfo } from '@/pages/staffManage/type';
import { Icon } from '@/components/widgets/icon';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { Space } from '@/components/widgets/space';
import { AddNoPhoneStaffDailog } from './addNoPhoneStaffDailog';
import { ResetPasswordDailog } from './resetPasswordDailog';
import { BatchAddStaffDrawer } from './batchAddStaffDrawer';
import { Select } from '@/components/widgets/select';

const Tag = memo(({ name }: { name: string }) => (
  <span className={css.tag}>{name}</span>
));

const AddStaffDropdown = memo(props => {
  const { state, getStaffList, batchAddStaffDrawerVisibleChange } =
    useAgentReducer(staffModelRef.current);
  const { hasSyncHelperPermission, hasTrackOrderPermission } = state;
  const [openDialog] = useDialogMethods<boolean>();

  const batchAddStaff = () => {
    batchAddStaffDrawerVisibleChange(true);
  };

  const addNoPhoneStaff = async () => {
    const res = await openDialog(AddNoPhoneStaffDailog, {
      permission: !(hasSyncHelperPermission && !hasTrackOrderPermission)
    });

    if (res) {
      getStaffList();
    }
  };

  return (
    <div style={{ minWidth: '255px' }}>
      <Button onClick={addNoPhoneStaff} type="primary">
        + 添加员工
      </Button>
      <Button onClick={batchAddStaff} type="primary" ghost>
        + 批量添加员工
      </Button>
    </div>
  );
});

export default memo(() => {
  const {
    state: {
      loading,
      dataSource,
      searchParams,
      hasSyncHelperPermission,
      hasTrackOrderPermission,
      batchAddStaffDrawerVisible,
      staffIds,
      page,
      totalCount
    },
    getStaffList,
    initialize,
    searchList,
    handleReset,
    handlePageChange,
    deleteStaff,
    changePhoneNumberVisible,
    handleFormChange,
    batchAddStaffDrawerVisibleChange,
    handleStaffIdsChange,
    handleBatchDelete
  } = useAgentReducer(staffModelRef.current);
  const [openDialog] = useDialogMethods<boolean>();

  useEffect(() => {
    initialize();
  }, []);

  const renderOperation = (record: StaffInfo) => {
    const {
      id,
      staffName,
      accountName,
      phoneNumber,
      permissionShops,
      permissionShopType,
      enterpriseManager
    } = record;

    const editItem = async () => {
      try {
        const res = await openDialog(AddNoPhoneStaffDailog, {
          id,
          staffName,
          accountName,
          phoneNumber,
          permissionShops,
          permissionShopType,
          permission: !(hasSyncHelperPermission && !hasTrackOrderPermission),
          enterpriseManager
        });
        if (res) {
          getStaffList();
        }
      } catch (e) {
        warnError(e);
      }
    };

    const resetPassword = async () => {
      try {
        const res = await openDialog(ResetPasswordDailog, {
          id,
          staffName,
          accountName
        });
      } catch (e) {
        warnError(e);
      }
    };

    return (
      <div>
        <span onClick={editItem} className={css.edit}>
          编辑
        </span>
        <span onClick={resetPassword} className={css.edit}>
          重置密码
        </span>
        <span onClick={() => deleteStaff(id)} className={css.delete}>
          删除
        </span>
      </div>
    );
  };

  const renderPermission = ({
    permissionShopType,
    permissionShops
  }: Pick<StaffInfo, 'permissionShopType' | 'permissionShops'>) => {
    if (permissionShopType === 'ALL') {
      return <div className={css.permission}>全部店铺</div>;
    }
    if (permissionShopType === 'NONE') {
      return <div className={css.permission}>——</div>;
    }
    return (
      <div className={css.permission}>
        {permissionShops.map(({ shopName, shopId }) => (
          <Tag key={shopId} name={shopName} />
        ))}
      </div>
    );
  };

  const renderPhoneNumber = ({
    id,
    phoneNumber,
    showPhoneNumber
  }: Pick<StaffInfo, 'phoneNumber' | 'showPhoneNumber' | 'id'>) => {
    return (
      <div className={css.phoneVisible}>
        {phoneNumber ? (
          <>
            <span>
              {showPhoneNumber
                ? phoneNumber
                : `${phoneNumber.slice(0, 3)}****${phoneNumber.slice(
                    phoneNumber.length - 4
                  )}`}
            </span>
            {showPhoneNumber ? (
              <Icon
                type="mimakejian"
                className={css.eyeIcon}
                onClick={() => changePhoneNumberVisible(id, false)}
              />
            ) : (
              <Icon
                type="mimabukejian"
                className={css.eyeIcon}
                onClick={() => changePhoneNumberVisible(id, true)}
              />
            )}
          </>
        ) : (
          '——'
        )}
      </div>
    );
  };

  return (
    <div>
      {hasTrackOrderPermission ? (
        <Alert>
          <div>
            {`1.员工将自动拥有智能跟单助手${
              hasSyncHelperPermission ? '和同步助手' : ''
            }的使用权限，登录千牛子账号即可使用`}
          </div>
          <div>2.配置权限：员工进入“店铺配置”页面的权限</div>
        </Alert>
      ) : null}
      <div className={css.searchWrapper}>
        <FormLike value={searchParams} onChange={handleFormChange}>
          <Space direction="horizontal" wrap>
            <ConditionUnit
              name="staffName"
              label="员工姓名"
              className={css.unit}
            >
              <Input placeholder="请输入搜索员工" onPressEnter={searchList} />
            </ConditionUnit>
            <ConditionUnit
              name="accountName"
              label="手机号/账号"
              className={css.unit}
            >
              <Input
                placeholder="请输入手机号/账号"
                onPressEnter={searchList}
              />
            </ConditionUnit>
            <Button type="primary" onClick={searchList}>
              搜索
            </Button>
            <Button onClick={handleReset}>重置</Button>
          </Space>
        </FormLike>
        <AddStaffDropdown />
      </div>
      <div className={css.staffList}>
        <Button disabled={!staffIds.length} onClick={handleBatchDelete}>
          批量删除
        </Button>
        <Table
          loading={loading}
          dataSource={dataSource}
          rowKey={(record: StaffInfo) => record.id}
          style={{ marginTop: 16 }}
          bordered
          rowSelection={{
            type: 'checkbox',
            onChange: handleStaffIdsChange,
            selectedRowKeys: staffIds
          }}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
            onChange: handlePageChange,
            current: page,
            pageSize: 10,
            total: totalCount
          }}
          scroll={{ x: 0 }}
        >
          <Column
            width={200}
            dataIndex="staffName"
            title="员工姓名"
            render={value => (
              <div style={{ color: '#666', whiteSpace: 'pre-wrap' }}>
                {value}
              </div>
            )}
          />
          <Column width={180} dataIndex="accountName" title="账号" />
          <Column
            width={180}
            dataIndex="phoneNumber"
            title="手机号"
            render={(value, { id, phoneNumber, showPhoneNumber }: StaffInfo) =>
              renderPhoneNumber({ id, phoneNumber, showPhoneNumber })
            }
          />
          {hasSyncHelperPermission && !hasTrackOrderPermission ? null : (
            <Column
              dataIndex="configAuthority"
              title="配置权限"
              render={(value, record: StaffInfo) => renderPermission(record)}
              width={200}
            />
          )}
          <Column
            dataIndex="enterpriseManager"
            title="企业权限"
            render={(value: boolean) => (value ? '企业管理' : '——')}
            width={200}
          />
          <Column
            dataIndex="operation"
            title="操作"
            width={180}
            render={(value, record: StaffInfo) => renderOperation(record)}
          />
        </Table>
      </div>

      <BatchAddStaffDrawer
        visible={batchAddStaffDrawerVisible}
        onClose={batchAddStaffDrawerVisibleChange}
      />
    </div>
  );
});
