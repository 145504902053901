export const huaDong = [
  '上海',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省'
];
export const huaBei = ['北京', '天津', '山西省', '河北省', '内蒙古自治区'];
export const huaZhong = ['湖南省', '湖北省', '河南省'];
export const huaNan = ['广东省', '海南省', '广西壮族自治区'];
export const dongBei = ['辽宁省', '吉林省', '黑龙江省'];
export const xiBei = [
  '陕西省',
  '甘肃省',
  '青海省',
  '新疆维吾尔自治区',
  '宁夏回族自治区'
];
export const xiNan = ['重庆', '云南省', '贵州省', '四川省', '西藏自治区'];
export const gangAoTai = ['香港特别行政区', '澳门特别行政区', '台湾'];

export const areaMap = new Map([
  ['huaDong', '华东'],
  ['huaBei', '华北'],
  ['huaZhong', '华中'],
  ['huaNan', '华南'],
  ['dongBei', '东北'],
  ['xiBei', '西北'],
  ['xiNan', '西南'],
  ['gangAoTai', '港澳台']
]);

export const areaProvincesMap = {
  huaDong,
  huaBei,
  huaZhong,
  huaNan,
  dongBei,
  xiBei,
  xiNan,
  gangAoTai
};
