// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tooltip_ZjdjW .ant-tooltip-inner,\n.style_tooltip_ZjdjW .ant-tooltip-arrow-content {\n  color: #666;\n  background-color: #fff;\n  font-size: 13px;\n}\n.style_wrapper_ER30Z {\n  max-height: 400px;\n  overflow: scroll;\n}\n.style_wrapper_ER30Z .style_provinceCtn_6-ti7 {\n  padding: 4px 4px 0;\n}\n.style_wrapper_ER30Z .style_provinceCtn_6-ti7 .style_province_rNlPq {\n  font-size: 16px;\n  font-weight: bold;\n  color: #666;\n}\n.style_wrapper_ER30Z .style_provinceCtn_6-ti7 .style_cityList_5-DXX {\n  padding-left: 16px;\n}\n.style_wrapper_ER30Z .style_provinceCtn_6-ti7 .style_cityList_5-DXX .style_city_9NFar {\n  color: #999;\n  font-size: 14px;\n  margin-top: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/addressDialog/addressPop/style.less"],"names":[],"mappings":"AAAA;;EAIM,WAAA;EACA,sBAAA;EACA,eAAA;AADN;AAKA;EACE,iBAAA;EACA,gBAAA;AAHF;AACA;EAII,kBAAA;AAFJ;AAFA;EAMK,eAAA;EACA,iBAAA;EACA,WAAA;AADL;AAPA;EAWM,kBAAA;AADN;AAVA;EAaQ,WAAA;EACA,eAAA;EACA,eAAA;AAAR","sourcesContent":[".tooltip {\n  :global {\n    .ant-tooltip-inner,\n    .ant-tooltip-arrow-content {\n      color: #666;\n      background-color: #fff;\n      font-size: 13px;\n    }\n  }\n}\n.wrapper {\n  max-height: 400px;\n  overflow: scroll;\n  .provinceCtn {\n    padding: 4px 4px 0;\n    .province {\n     font-size: 16px;\n     font-weight: bold;\n     color: #666;\n    }\n    .cityList {\n      padding-left: 16px;\n      .city {\n        color: #999;\n        font-size: 14px;\n        margin-top: 4px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "style_tooltip_ZjdjW",
	"wrapper": "style_wrapper_ER30Z",
	"provinceCtn": "style_provinceCtn_6-ti7",
	"province": "style_province_rNlPq",
	"cityList": "style_cityList_5-DXX",
	"city": "style_city_9NFar"
};
export default ___CSS_LOADER_EXPORT___;
