// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_content_Kl3dC {\n  margin-top: 24px;\n}\n.style_tableHeader_WrxGv {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 24px;\n}\n.style_tableName_MT7Sb {\n  font-weight: bold;\n  font-size: 18px;\n  color: #333333;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/statisticComponents/card/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;AAAF;AAGA;EACE,iBAAA;EACA,eAAA;EACA,cAAA;AADF","sourcesContent":[".content {\n  margin-top: 24px;\n}\n\n.tableHeader {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 24px;\n}\n\n.tableName {\n  font-weight: bold;\n  font-size: 18px;\n  color: #333333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "style_content_Kl3dC",
	"tableHeader": "style_tableHeader_WrxGv",
	"tableName": "style_tableName_MT7Sb"
};
export default ___CSS_LOADER_EXPORT___;
