import { Http, http } from '@/libs/http';
import {
  AuthorizeConfig,
  ModifyPasswordParams,
  SendSmsCodeParams,
  SmsModifyPasswordParams
} from './type';

export class StaffAccountRequest {
  private http: Http = http('/api/staff/account');

  getAuthorizeConfig(): Promise<AuthorizeConfig> {
    return this.http.path('authorize-config').get<AuthorizeConfig>();
  }

  smsModifyPassword(params: SmsModifyPasswordParams): Promise<void> {
    return this.http
      .path('modify-password-by-account')
      .setRequestBody(params)
      .post<void>();
  }

  sendSmsCode(params: SendSmsCodeParams): Promise<void> {
    return this.http
      .path('send-sms-code-by-account')
      .setRequestBody(params)
      .post<void>();
  }

  checkOldPassword(oldPassword: string): Promise<void> {
    return this.http
      .path('check-old-password')
      .setRequestBody({ oldPassword })
      .post<void>();
  }

  modifyPassword(params: ModifyPasswordParams): Promise<void> {
    return this.http
      .path('modify-password')
      .setRequestBody(params)
      .post<void>();
  }
}

export default new StaffAccountRequest();
