// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_pop_7ECPh .ant-popover-inner-content {\n  padding: 4px 0 !important;\n}\n.style_popContent_xG9Tw:hover {\n  color: #5a91ff;\n}\n.style_goods_deQ5H {\n  width: 320px;\n}\n.style_goods_deQ5H .style_goodsTitle_yt5EO {\n  line-height: 30px;\n  padding-left: 12px;\n  font-weight: bold;\n  border-bottom: 1px solid #e6e8ed;\n}\n.style_goods_deQ5H .style_goodsWrap_evN5t {\n  overflow-y: auto;\n  max-height: 194px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/goodsSkuPop/style.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;AAIA;EACE,YAAA;AAFF;AACA;EAII,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gCAAA;AAFJ;AALA;EAWI,gBAAA;EACA,iBAAA;AAHJ","sourcesContent":[".pop {\n  :global(.ant-popover-inner-content) {\n    padding: 4px 0 !important;\n  }\n  &Content:hover {\n    color: #5a91ff;\n  }\n}\n\n.goods {\n  width: 320px;\n\n  .goodsTitle {\n    line-height: 30px;\n    padding-left: 12px;\n    font-weight: bold;\n    border-bottom: 1px solid #e6e8ed;\n  }\n\n  .goodsWrap {\n    overflow-y: auto;\n    max-height: 194px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pop": "style_pop_7ECPh",
	"popContent": "style_popContent_xG9Tw",
	"goods": "style_goods_deQ5H",
	"goodsTitle": "style_goodsTitle_yt5EO",
	"goodsWrap": "style_goodsWrap_evN5t"
};
export default ___CSS_LOADER_EXPORT___;
