import { Http, http } from '@/libs/http';
import { BrowserLoginParams } from './type';

export class WebApi {
  private http: Http = http('/api');

  browserLogin(params: BrowserLoginParams): Promise<void> {
    return this.http.path('browser-login').setRequestBody(params).post();
  }

  browserLogout(): Promise<void> {
    return this.http.path('browser-logout').post();
  }

  getCaptcha(): Promise<string> {
    return this.http.path('captcha/get').post();
  }
}

export default new WebApi();
