import { Http, http } from '@/libs/http';
import { ItemSearchQuery, ItemWithSkusWebDto } from './type';
import { PageDetail } from '@/types/pageDetail';

export class ItemSku {
  private http: Http = http('/api/shop/item');

  itemSkus(params: ItemSearchQuery): Promise<PageDetail<ItemWithSkusWebDto>> {
    return this.http.path('skus').setRequestBody(params).post();
  }

  itemSkuDetails(taobaoSkuIds: string[]): Promise<ItemWithSkusWebDto[]> {
    return this.http
      .path('sku-details')
      .setRequestBody({ taobaoSkuIds })
      .post();
  }
}

export const itemSkuRequest = new ItemSku();
