import { Http, http } from '@/libs/http';
import { EsItem } from '@/types/esItem';
import { EsItemCommand, BatchSelectDto } from './type';
import { SkuWebDto } from '../skuCollocation/type';

export class ChatpeerRequest {
  private http: Http = http('/api/shop/item');

  esItemList(
    query: EsItemCommand
  ): Promise<{ items: EsItem[]; totalResult: number }> {
    return this.http
      .path('search')
      .setRequestParams(query)
      .get<{ items: EsItem[]; totalResult: number }>();
  }

  batchFetchList(query: {
    searchTaobaoIids: string;
    selectedTaobaoIids: string[];
  }): Promise<BatchSelectDto> {
    return this.http
      .path('batch-check-taobaoiid')
      .setRequestBody(query)
      .post<BatchSelectDto>();
  }

  getByTaoBaoIIds(query: { searchTaobaoIids: string[] }): Promise<EsItem[]> {
    return this.http
      .path('get-by-taobao-iids')
      .setRequestBody(query)
      .post<EsItem[]>();
  }

  skusByTaobaoIid(taobaoIid: string): Promise<SkuWebDto[]> {
    return this.http
      .path('skus-by-taobao-iid')
      .setRequestBody({ taobaoIid })
      .post();
  }
}

export default new ChatpeerRequest();
