// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_EyIJ6 {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background: linear-gradient(180deg, #d9ecff, #fff);\n}\n.style_container_tt5IO {\n  display: flex;\n  flex-direction: column;\n  max-height: 555px;\n  width: 760px;\n  height: 500px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px #d9d9d9;\n  padding: 60px 180px;\n  background-color: #fff;\n}\n.style_container_tt5IO .style_item_uEh-0 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 24px 0px;\n}\n.style_container_tt5IO .style_resetPasswordBtn_auZm2 {\n  display: flex;\n  margin: 24px 0 0 !important;\n  width: 100%;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/findPassword/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kDAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gCAAA;EACA,mBAAA;EACA,sBAAA;AAAF;AATA;EAYI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAfA;EAmBI,aAAA;EACA,2BAAA;EACA,WAAA;EACA,uBAAA;AADJ","sourcesContent":[".wrapper {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background: linear-gradient(180deg, #d9ecff, #fff);\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  max-height: 555px;\n  width: 760px;\n  height: 500px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px #d9d9d9;\n  padding: 60px 180px;\n  background-color: #fff;\n\n  .item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 24px 0px;\n  }\n\n  .resetPasswordBtn {\n    display: flex;\n    margin: 24px 0 0 !important;\n    width: 100%;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_EyIJ6",
	"container": "style_container_tt5IO",
	"item": "style_item_uEh-0",
	"resetPasswordBtn": "style_resetPasswordBtn_auZm2"
};
export default ___CSS_LOADER_EXPORT___;
