import React, { PropsWithChildren, memo, useState } from 'react';
import { ItemWithSkusWebDto } from '@/requests/itemSku/type';
import { getThumbnail } from '@/utils/image';
import css from './style.less';
import { Icon } from '@/components/widgets/icon';

type ItemProps = {
  item: ItemWithSkusWebDto;
};

export const Item = memo((props: PropsWithChildren<ItemProps>) => {
  const { item, children } = props;
  const { title, picUrl, skuList, taobaoIid } = item;

  const [expand, setExpand] = useState(false);

  const redirectToTaoBao = (id: string) => {
    window.open(`https://item.taobao.com/item.htm?id=${id}`);
  };

  return (
    <div>
      <div className={css.itemWrapper}>
        <img
          src={getThumbnail(picUrl)}
          className={css.img}
          onClick={() => redirectToTaoBao(taobaoIid)}
        />

        <div>
          <div className={css.title}>{title}</div>
          <div>
            已选{skuList.length}个SKU
            <Icon
              className={css.icon}
              type="xiala"
              onClick={() => setExpand(pre => !pre)}
            />
          </div>
        </div>
      </div>
      <div>{expand ? children : null}</div>
    </div>
  );
});
