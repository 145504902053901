import React, { memo } from 'react';
import { Tooltip as SourceTooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import css from './style.less';

export const Tooltip = memo((props: TooltipProps) => {
  const { overlayClassName = '', ...rest } = props;
  return (
    <SourceTooltip
      {...rest}
      overlayClassName={`${overlayClassName} ${css.tooltip}`}
    />
  );
});
