// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_searchWrapper_D4i3x {\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n}\n.style_searchItem_PU0-p {\n  display: flex;\n  align-items: center;\n  margin-right: 28px;\n}\n.style_operationLogList_Zh4O7 {\n  background: #ffffff;\n  border-radius: 6px;\n  padding: 20px 24px;\n}\n.style_content_XGBAY {\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operationLog/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACA;EACE,qBAAA;EACA,sBAAA;AACF","sourcesContent":[".searchWrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n}\n.searchItem {\n  display: flex;\n  align-items: center;\n  margin-right: 28px;\n}\n.operationLogList {\n  background: #ffffff;\n  border-radius: 6px;\n  padding: 20px 24px;\n}\n.content {\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "style_searchWrapper_D4i3x",
	"searchItem": "style_searchItem_PU0-p",
	"operationLogList": "style_operationLogList_Zh4O7",
	"content": "style_content_XGBAY"
};
export default ___CSS_LOADER_EXPORT___;
