// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_upDRC {\n  display: inline-flex;\n  align-items: center;\n  margin-left: 4px;\n}\n.style_wrapper_upDRC .style_helpIcon_2X\\+qm {\n  vertical-align: middle;\n  font-size: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/helpTooltip/style.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAJA;EAMI,sBAAA;EACA,eAAA;AACJ","sourcesContent":[".wrapper {\n  display: inline-flex;\n  align-items: center;\n  margin-left: 4px;\n\n  .helpIcon {\n    vertical-align: middle;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_upDRC",
	"helpIcon": "style_helpIcon_2X+qm"
};
export default ___CSS_LOADER_EXPORT___;
