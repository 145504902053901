import React, { memo } from 'react';
import AutoTooltipText from '@/components/widgets/autoTooltipText';
import { getThumbnail } from '@/utils/image';
import { SkuWebDto } from '@/requests/itemSku/type';
import css from './style.less';

type SkuProps = {
  sku: SkuWebDto;
  skuPicUrl: string;
};

export const Sku = memo((props: SkuProps) => {
  const { sku, skuPicUrl } = props;
  const { skuProperties, taobaoIid } = sku;

  return (
    <div className={css.skuWrapper}>
      <img src={getThumbnail(skuPicUrl)} className={css.img} />

      <AutoTooltipText className={css.propertyText} taobaoIid={taobaoIid}>
        {skuProperties.map(property => {
          return (
            <span className={css.title} key={property.propertyNameId}>
              {property.propertyName}：{property.propertyValue}；
            </span>
          );
        })}
      </AutoTooltipText>
    </div>
  );
});
