// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_icon_qedsq {\n  margin-left: 8px;\n  font-size: 16px;\n  color: #3d7fff;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/itemSelector/style.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AADF","sourcesContent":["@import '~@/css/color.less';\n\n.icon {\n  margin-left: 8px;\n  font-size: 16px;\n  color: @link-color;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "style_icon_qedsq"
};
export default ___CSS_LOADER_EXPORT___;
