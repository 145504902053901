// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_item_Qi9pR {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 0;\n}\n.style_multipleSelect_sTZgj {\n  width: 408px;\n  margin: 7px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/exchangeReason/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACF;AAEA;EACE,YAAA;EACA,aAAA;AAAF","sourcesContent":[".item {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 0;\n}\n\n.multipleSelect {\n  width: 408px;\n  margin: 7px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "style_item_Qi9pR",
	"multipleSelect": "style_multipleSelect_sTZgj"
};
export default ___CSS_LOADER_EXPORT___;
