import React, { memo, useCallback, useMemo } from 'react';
import { Icon } from '@/components/widgets/icon';
import classNames from 'classnames';
import AutoTooltipText from '@/components/widgets/autoTooltipText';
import { getThumbnail } from '@/utils/image';
import { linkToGoodsDetail } from '../goodsItem';
import css from './style.less';

type SkuItemProps = {
  type?: 'wait' | 'selected';
  selected?: boolean;
  skuItem?: any;
  onSelect?(item: any): void;
};

export const SkuItem = memo((props: SkuItemProps) => {
  const { type, selected, skuItem, onSelect } = props;
  const { picUrl, title, taobaoIid, outerIid } = skuItem || {};

  const handleImgClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    linkToGoodsDetail(id);
  };

  const handleClick = e => {
    e.stopPropagation();
    onSelect?.(skuItem);
  };

  const buildIcon = () => {
    if (props.type) {
      return type === 'wait' ? (
        <Icon
          type={`${selected ? 'duoxuan-xuanzhong' : 'duoxuan-weixuanzhong'}`}
          className={classNames(
            css.selectedIcon,
            selected ? css.selectedIconActive : css.selectedIconHover
          )}
        />
      ) : (
        <Icon type="duoxuan-yichu" className={css.deleteIcon} />
      );
    }
    return null;
  };

  const buildImageWithStatus = () => (
    <div className={css.imgBox}>
      <img
        src={getThumbnail(picUrl)}
        className={css.img}
        onClick={e => handleImgClick(e, taobaoIid)}
      />
    </div>
  );

  return (
    <div
      className={`${css.option} ${props.selected ? css.selected : ''}`}
      onClick={handleClick}
    >
      {buildImageWithStatus()}

      <AutoTooltipText className={css.nameText} taobaoIid={taobaoIid}>
        <span
          className={css.nameLink}
          onClick={e => handleImgClick(e, taobaoIid)}
        >
          {title}
        </span>
      </AutoTooltipText>

      {buildIcon()}
    </div>
  );
});

export const Sku = memo((props: any) => {
  const { type, selected, sku, skuPicUrl, onSelect } = props;
  const { skuProperties, taobaoIid } = sku;

  const handleImgClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    linkToGoodsDetail(taobaoIid);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelect?.(sku);
  };

  const buildIcon = () => {
    if (props.type) {
      return type === 'wait' ? (
        <Icon
          type={`${selected ? 'duoxuan-xuanzhong' : 'duoxuan-weixuanzhong'}`}
          className={classNames(
            css.selectedIcon,
            selected ? css.selectedIconActive : css.selectedIconHover
          )}
        />
      ) : (
        <Icon type="duoxuan-yichu" className={css.deleteIcon} />
      );
    }
    return null;
  };

  const buildImageWithStatus = () => (
    <div className={css.imgBox}>
      <img
        src={getThumbnail(skuPicUrl)}
        className={css.img}
        onClick={e => handleImgClick(e)}
      />
    </div>
  );

  return (
    <div
      className={`${css.option} ${props.selected ? css.selected : ''} ${
        type === 'wait' ? css.sku : ''
      }`}
      onClick={handleClick}
    >
      {buildImageWithStatus()}
      <AutoTooltipText className={css.nameText} taobaoIid={taobaoIid}>
        {skuProperties.map(property => {
          return (
            <span className={css.nameLink} key={property.propertyNameId}>
              {property.propertyName}：{property.propertyValue}；
            </span>
          );
        })}
      </AutoTooltipText>

      {buildIcon()}
    </div>
  );
});
