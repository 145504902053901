// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index_statistic_Ww\\+gu {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  height: 100px;\n  background: #fff;\n  margin-bottom: 24px;\n  text-align: center;\n  padding: 10px 0;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n.index_statistic_Ww\\+gu .index_statisticItem_PEqDX {\n  flex: 1;\n}\n.index_statistic_Ww\\+gu .index_statisticItem_PEqDX:not(:last-child) {\n  border-right: #f0f2f5 solid 1px;\n}\n.index_statistic_Ww\\+gu .index_statisticItem_PEqDX .index_statisticName_IjKhE {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 14px;\n  font-size: 14px;\n  color: #666666;\n}\n.index_statistic_Ww\\+gu .index_statisticItem_PEqDX .index_statisticTotal_ngvio {\n  font-weight: Bold;\n  font-size: 24px;\n  color: #3d7eff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/statisticComponents/statistic/index.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,2CAAA;AACF;AAVA;EAWI,OAAA;AAEJ;AADI;EACE,+BAAA;AAGN;AAhBA;EAiBM,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAEN;AAxBA;EAyBM,iBAAA;EACA,eAAA;EACA,cAAA;AAEN","sourcesContent":[".statistic {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  height: 100px;\n  background: #fff;\n  margin-bottom: 24px;\n  text-align: center;\n  padding: 10px 0;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n  .statisticItem {\n    flex: 1;\n    &:not(:last-child) {\n      border-right: #f0f2f5 solid 1px;\n    }\n\n    .statisticName {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      margin-top: 14px;\n      font-size: 14px;\n      color: #666666;\n    }\n    .statisticTotal {\n      font-weight: Bold;\n      font-size: 24px;\n      color: #3d7eff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic": "index_statistic_Ww+gu",
	"statisticItem": "index_statisticItem_PEqDX",
	"statisticName": "index_statisticName_IjKhE",
	"statisticTotal": "index_statisticTotal_ngvio"
};
export default ___CSS_LOADER_EXPORT___;
