// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_item_nVReX {\n  display: flex;\n  flex-direction: column;\n}\n.style_item_nVReX .style_radio_ACkN3 {\n  margin-bottom: 8px;\n}\n.style_item_nVReX .style_presaleOption_pbqC5 {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.style_item_nVReX .style_link_CtMEl {\n  cursor: pointer;\n  color: #3d7fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/presaleActivity/style.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AADA;EAKI,kBAAA;AADJ;AAJA;EASI,uBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AATA;EAeI,eAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '~@/css/color.less';\n\n.item {\n  display: flex;\n  flex-direction: column;\n\n  .radio {\n    margin-bottom: 8px;\n  }\n\n  .presaleOption {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n\n  .link {\n    cursor: pointer;\n    color: @link-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "style_item_nVReX",
	"radio": "style_radio_ACkN3",
	"presaleOption": "style_presaleOption_pbqC5",
	"link": "style_link_CtMEl"
};
export default ___CSS_LOADER_EXPORT___;
