import React, { memo, useState } from 'react';
import { DataNode } from 'antd/lib/tree';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { TradeStatuses } from '@/types/tradeStatus';
import { message } from '@/components/widgets/message';
import { Dialog } from '@/components/widgets/modal';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { Alert } from '@/components/layouts/alert';
import { Tree } from '@/components/widgets/tree';
import css from './style.less';

const tradeStatusesMap = {
  WAIT_ORDER: '未下单',
  WAIT_PAY: '等待买家付款',
  WAIT_SEND: '等待卖家发货',
  SENT: '卖家已发货',
  SELLER_CONSIGNED_PART: '卖家部分发货',
  SUCCESS: '交易成功',
  CLOSE: '交易关闭',
  FRONT_PAID_FINAL_NO_PAID: '待付尾款',
  FRONT_PAID_FINAL_PAY_TIMEOUT: '尾款支付超时'
};

const tradeStatuseSTreeData: DataNode[] = [
  {
    key: 'ALL',
    title: '全部',
    children: Object.entries(tradeStatusesMap).map(([key, title]) => ({
      key,
      title
    }))
  }
];

export const TradeStatusesSelectModal = memo(
  (
    props: DialogDeployProps<{
      tradeStatuses: TradeStatuses[];
      tradeStatusesType: 'ALL' | 'POINT';
    }>
  ) => {
    const { closeDialog, params } = props;
    const { tradeStatuses, tradeStatusesType } = params;
    const [type, setType] = useState(tradeStatusesType);
    const [status, setStatus] =
      useState<(TradeStatuses | 'ALL')[]>(tradeStatuses);
    const handleOk = () => {
      if (type === 'POINT' && !status.length) {
        message.warning('请选择订单状态');
        return;
      }
      closeDialog({
        tradeStatuses:
          type === 'ALL' ? undefined : status.filter(i => i !== 'ALL')
      });
    };

    return (
      <Dialog
        title="选择订单状态"
        onCancel={closeDialog}
        width={540}
        onOk={handleOk}
      >
        <div className={css.radioWrap}>
          咨询商品：
          <RadioGroup value={type} onChange={e => setType(e.target.value)}>
            <Radio value="ALL">不限</Radio>
            <Radio value="POINT">指定订单状态</Radio>
          </RadioGroup>
        </div>
        {type === 'ALL' ? <div className={css.noLimitedWrap}>不限</div> : null}
        {type === 'POINT' ? (
          <div>
            <Alert>订单状态可多选，满足任一选中状态即可回复</Alert>
            <div className={css.treeHeader}>全部分类</div>
            <div className={css.treeContent}>
              <Tree
                checkedKeys={status}
                defaultExpandAll
                autoExpandParent
                defaultExpandParent
                checkable
                treeData={tradeStatuseSTreeData}
                onCheck={e => setStatus(e as (TradeStatuses | 'ALL')[])}
              />
            </div>
          </div>
        ) : null}
      </Dialog>
    );
  }
);
