import React, { memo, useEffect, useMemo, useState } from 'react';
import { useAgentReducer, useAgentSelector } from 'use-agent-reducer';
import { Popover } from '@/components/widgets/popover';
import moment from 'moment';
import css from './style.less';
import {
  CustomPopProps,
  ValidPaymentPopProps,
  RemindTradePopProps,
  RelatedValidTimePopProps,
  ConsignPopType,
  CategoryPopProps,
  TradeStatusesType,
  RemindContentProps,
  TransferWordTypePopProps
} from './type';
import { Tooltip } from 'antd';
import parseService from '@/services/parse/index';
import classNames from 'classnames';
import { DataNode } from 'antd/lib/tree';
import { useProvinceNum } from '@/modules/address';
import { Tree } from '@/components/widgets/tree';
import service from '@/modules/category/service';
import { categoryRef } from '@/modules/category';
import { AddressPop } from '../addressDialog/addressPop';
import { CheckStatusTags } from '../components/customTextarea';
import { ImageLogContent } from '../imageLogContent';

const timeStatusMap = {
  INVALID: '已失效',
  VALIDING: '生效中',
  WAIT_VALID: '未生效'
};

const tradeStatusesMap = {
  CLOSE: '交易关闭',
  CLOSE_NO_PAID: '交易关闭（且未付款）',
  FRONT_PAID_FINAL_NO_PAID: '待付尾款',
  FRONT_PAID_FINAL_PAY_TIMEOUT: '尾款支付超时',
  SELLER_CONSIGNED_PART: '卖家部分发货',
  SENT: '卖家已发货',
  SUCCESS: '交易成功',
  WAIT_ORDER: '未下单',
  WAIT_PAY: '等待买家付款',
  WAIT_SEND: '等待卖家发货'
};

const timeStatusStyleMap = {
  INVALID: css.invalid,
  VALIDING: css.valid,
  WAIT_VALID: css.wait
};

const consignTypesMap = {
  ALL_CONSIGNED: '整单一起发货',
  PART_CONSIGNED: '拆分发货（部分发货）',
  PART_CONSIGNED_ALL: '拆分发货（全部发完）'
};

const transferWordTypeMap = {
  ANY_BUYER_MSG: '转接前有买家消息',
  NO_BUYER_MSG: '转接前无买家消息'
};

const CustomPop = memo(
  ({ label = '', content = '', className }: CustomPopProps) => (
    <>
      {content ? (
        <Popover content={label} placement="left">
          <div className={className}>{content}</div>
        </Popover>
      ) : null}
    </>
  )
);

export const RelatedValidTimePop = memo(
  ({ taskStatus, validDateRange, validDateType }: RelatedValidTimePopProps) => {
    const { fixedDateTimeRange, cycleDateTimeRange } = validDateRange;
    const { dateRange, timeRange } = cycleDateTimeRange || {
      dateRange: {
        end: moment().format('YYYY-MM-DD'),
        start: moment().format('YYYY-MM-DD')
      },
      timeRange: {
        start: '00:00:00',
        end: '00:00:00'
      }
    };

    const formatValidTime = () => {
      if (validDateType === 'FIXED_TIME') {
        return (
          <>
            <div>
              {dateRange.start} 至 {dateRange.end}
            </div>
            <div>
              每天 {timeRange.start} 至 {timeRange.end}
            </div>
          </>
        );
      }
      return `${fixedDateTimeRange.start} 至 ${fixedDateTimeRange.end}`;
    };
    return (
      <CustomPop
        className={timeStatusStyleMap[taskStatus]}
        content={timeStatusMap[taskStatus]}
        label={formatValidTime()}
      />
    );
  }
);

const formatPrice = (val: string | number) => `¥${Number(val).toFixed(2)}`;
const textMap = {
  MORE_THEN_EQUAL: '大于等于',
  LESS_THEN_EQUAL: '小于等于',
  BETWEEN: ''
};
export const ValidPaymentPop = memo(
  ({ validPaymentRange }: ValidPaymentPopProps) => {
    const { operator, max, min } = validPaymentRange;
    const text =
      operator === 'BETWEEN'
        ? `${formatPrice(min)} ~ ${formatPrice(max)}`
        : `${textMap[operator]}${formatPrice(min)}`;
    return <CustomPop className={css.label} content={text} label={text} />;
  }
);

const refundTypesMap = {
  HAS_GOODS: '退货退款',
  CONSIGNED_NO_GOODS: '已发货仅退款',
  NO_CONSIGN_NO_GOODS: '未发货仅退款'
};

export const RefundTypePop = memo(
  ({ refundTypes }: { refundTypes: string[] }) => {
    const text = refundTypes.map(i => refundTypesMap[i]).join('、');
    return (
      <CustomPop
        className={css.label}
        content={`${refundTypes.length}个退款类型`}
        label={text}
      />
    );
  }
);
const signTypeMap = {
  UNSIGNED: '未签收',
  SIGNED: '已签收'
};

export const SignTypePop = memo(({ signType }: { signType: string }) => {
  const text = signTypeMap[signType];
  return <CustomPop className={css.label} content={text} label={text} />;
});

export const AddressesPop = memo(({ cityList }: { cityList: number[] }) => {
  const provinceNum = useProvinceNum(cityList);
  return (
    <AddressPop cityList={cityList}>
      <span className={classNames(css.label, css.addressLabel)}>
        共{provinceNum}个省{cityList.length}个市
      </span>
    </AddressPop>
  );
});
const typeMap = {
  BUYER_ASKED_TRADE: '有咨询订单',
  SILENCE_TRADE: '静默订单'
};
export const RemindTradePop = memo(({ tradeTypes }: RemindTradePopProps) => {
  const text =
    tradeTypes.length === 2 ? '有咨询订单、静默订单' : typeMap[tradeTypes[0]];
  return (
    <CustomPop
      className={css.label}
      content={text}
      label={`订单类型：${text}`}
    />
  );
});

export const MessageContent = (props: {
  message?: string;
  className?: string;
}): JSX.Element => {
  const { message, className } = props;

  const parsed = message
    ? parseService.parseWithEmoji(message).map(item => ({
        ...item,
        content: (item.content || '').replace(/\r/g, '\n')
      }))
    : [];

  const render = useMemo(
    () =>
      parsed.map(({ content: chatContent, image }, index) => {
        if (image) {
          return (
            <img
              src={chatContent}
              key={`${index.toString()}`}
              className={css.img}
              alt=""
            />
          );
        }
        return <span key={`${index.toString()}`}>{chatContent}</span>;
      }),
    [parsed]
  );

  return (
    <span className={classNames(css.textContent, className)}>{render}</span>
  );
};

export const SubsectionContent = memo(
  (props: { contents?: string[]; imageId?: number }) => {
    const { contents, imageId } = props;

    if (!contents) {
      return null;
    }

    const [firstContent, secondContent] = contents;

    return (
      <div>
        {firstContent ? (
          <div className={css.textColor}>
            <MessageContent message={firstContent} />
          </div>
        ) : null}
        {secondContent ? (
          <>
            <div className={css.subsectionDivider}>
              ------ 分两条消息 ------
            </div>
            <div className={css.textColor}>
              <MessageContent message={secondContent} />
            </div>
          </>
        ) : null}
        {imageId ? (
          <div className={css.imageContent}>
            <ImageLogContent imageId={imageId} className={css.img} />
          </div>
        ) : null}
      </div>
    );
  }
);

export const RemindContent = memo((props: RemindContentProps) => {
  const { contents, id, onEdit } = props;

  return (
    <div className={css.ellipsis}>
      {contents.slice(0, 3).map((v, i) => (
        <div key={`content_${i.toString()}`}>
          {v.heraCheckStatus ? (
            <CheckStatusTags
              type={v.heraCheckStatus}
              checkFailReason={v?.checkFailReason}
            />
          ) : null}
          {contents.length > 1 ? (
            <span className={css.pushText}>随机推送{i + 1}</span>
          ) : null}
          <SubsectionContent contents={v.contents} imageId={v.imageId} />
        </div>
      ))}
      {contents.length > 3 ? (
        <div style={{ marginTop: '-20px' }}>
          <span className={css.ellipsisGap}>...</span>
          <Tooltip
            title={
              <span className={css.hover} onClick={() => onEdit?.(id!)}>
                点击 查看/编辑 全部内容
              </span>
            }
          >
            <span className={css.ellipsisBtn} onClick={() => onEdit?.(id!)}>
              共 {contents.length} 个随机推送内容
            </span>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
});

export const TradeStatusesPop = memo((props: TradeStatusesType) => {
  const {
    tradeStatuses = [],
    className,
    prefix = '已选',
    suffix = '个状态'
  } = props;
  return (
    <>
      <Popover
        content={
          <>
            <div
              className={css.statusHeader}
            >{`共${tradeStatuses.length}个状态`}</div>
            {tradeStatuses.map(i => (
              <div className={css.tradeStatuses} key={i}>
                {tradeStatusesMap[i]}
              </div>
            ))}
          </>
        }
        placement="left"
      >
        <div
          className={className}
        >{`${prefix}${tradeStatuses.length}${suffix}`}</div>
      </Popover>
    </>
  );
});

export const ConsignPop = memo((props: ConsignPopType) => {
  const { consignTypes = [] } = props;

  if (consignTypes.length === 3) {
    return null;
  }

  return (
    <>
      {consignTypes.map(i => (
        <CustomPop
          key={i}
          className={classNames(css.label)}
          content={consignTypesMap[i]}
          label={`订单发货类型：${consignTypesMap[i]}`}
        />
      ))}
    </>
  );
});

export const CategoryPop = memo((props: CategoryPopProps) => {
  const {
    title = '',
    label = '',
    ids = [],
    className,
    isTablePop = false
  } = props;

  const { state: categoryState } = useAgentReducer(categoryRef.current);
  const { sellerOriginList } = categoryState;
  const allParenteCids = Array.from(
    new Set(sellerOriginList.map(i => i.parentCid))
  );

  const categoryNum = ids.filter(i => !allParenteCids.includes(i)).length;

  const buildTreeWithParentId = (itemCategories, ids): DataNode[] => {
    if (itemCategories.length === 0 || ids.length === 0) {
      return [];
    }

    const listData = itemCategories.filter(item => ids.includes(item.cid));
    const parenteIds = Array.from(
      new Set(listData.map(item => item.parentCid))
    );
    const cids = listData.map(item => item.cid);
    const totalIds = [...cids, ...parenteIds];
    const totalData = itemCategories.filter(item =>
      totalIds.includes(item.cid)
    );

    return service.buildItemCategoryTree(totalData);
  };

  const treeData = useMemo(
    () => buildTreeWithParentId(sellerOriginList, ids),
    [sellerOriginList, ids]
  );

  return (
    <Popover
      placement="bottomLeft"
      title={title || `共${categoryNum}个店铺分类`}
      content={
        <div style={{ maxHeight: '192px', overflow: 'auto' }}>
          <Tree treeData={treeData} defaultExpandAll />
        </div>
      }
    >
      <span className={className}>
        {label ||
          (isTablePop
            ? `${categoryNum}个关联店铺分类`
            : `已选择 ${categoryNum} 个分类`)}
      </span>
    </Popover>
  );
});

export const TransferWordTypePop = memo((props: TransferWordTypePopProps) => {
  const { transferWordType } = props;

  return (
    <CustomPop
      className={classNames(css.label, css.addressLabel)}
      content={transferWordTypeMap[transferWordType]}
      label={`${transferWordTypeMap[transferWordType]}`}
    />
  );
});
