// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_header_hktql {\n  margin-bottom: 22px;\n}\n.style_status_wJSC4 {\n  margin-left: 100px;\n  color: #909090;\n  font-weight: 400;\n  font-size: 12px;\n}\n.style_statusOnline_h7DA0 {\n  margin-right: 24px;\n}\n.style_badge_2FL4L {\n  display: inline-block;\n  width: 8px;\n  height: 8px;\n  margin-right: 6px;\n}\n.style_badge_2FL4L.style_online_BGiQQ {\n  background-color: #6699ff;\n}\n.style_badge_2FL4L.style_offline_Iowmg {\n  background-color: #cccccc;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/realTimeLogin/recordDialog/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAEE;EACE,kBAAA;AAAJ;AAIA;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;AAFF;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ","sourcesContent":[".header {\n  margin-bottom: 22px;\n}\n\n.status {\n  margin-left: 100px;\n  color: #909090;\n  font-weight: 400;\n  font-size: 12px;\n\n  &Online {\n    margin-right: 24px;\n  }\n}\n\n.badge {\n  display: inline-block;\n  width: 8px;\n  height: 8px;\n  margin-right: 6px;\n\n  &.online {\n    background-color: #6699ff;\n  }\n\n  &.offline {\n    background-color: #cccccc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style_header_hktql",
	"status": "style_status_wJSC4",
	"statusOnline": "style_statusOnline_h7DA0",
	"badge": "style_badge_2FL4L",
	"online": "style_online_BGiQQ",
	"offline": "style_offline_Iowmg"
};
export default ___CSS_LOADER_EXPORT___;
