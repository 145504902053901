import React, { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useAgentReducer, useAgentSelector } from 'use-agent-reducer';
import moment from 'moment';
import { menuSelectionRef } from '@/entry/menu/model';
import { useTitleRender } from '@/hooks/titleRender';
import classNames from 'classnames';
import { Tooltip } from '@/components/widgets/tooltip';
import { Icon } from '@/components/widgets/icon';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import ExportCenter from '@/components/works/exportCenter';
import { isEnterprisePage } from '..';
import { Divider } from '@/components/widgets/divider';
import { Menu, MenuItem } from '@/components/widgets/menu';
import { Dropdown } from '@/components/widgets/dropdown';
import { shopModelRef } from '@/modules/shop';
import css from './style.less';

// 导出中心
const ExportCenterInfo = memo(() => {
  const [openDialog] = useDialogMethods();

  const showExportCenter = () => {
    openDialog(ExportCenter);
  };

  return (
    <Tooltip
      placement="bottom"
      zIndex={1000}
      title={<div className={css.tooltipsText}>导出中心</div>}
      color="white"
    >
      <Icon
        type="Download"
        className={css.headerIconDetail}
        onClick={showExportCenter}
      />
    </Tooltip>
  );
});

const HelpManual = memo(() => {
  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem>
            <a
              className={css.aTag}
              href="https://www.yuque.com/xiamiyekebaochao/qhn9iu"
              target="__blank"
            >
              操作手册
            </a>
          </MenuItem>
          <MenuItem>
            <a
              className={css.aTag}
              href="https://www.yuque.com/xiamiyekebaochao/gt65ln/efit1526cphp7gk0?singleDoc#"
              target="__blank"
            >
              更新日志
            </a>
          </MenuItem>
        </Menu>
      }
    >
      <Icon type="bangzhuwendang" className={css.headerIcon} />
    </Dropdown>
  );
});

const ExpiryDate = ({ expiryDate }: { expiryDate: string }) => {
  const expiryDateMoment = moment(expiryDate, 'YYYY-MM-DD');
  const currentDate = moment();
  const daysUntilExpiry = expiryDateMoment.diff(currentDate, 'days');

  return (
    <Tooltip
      placement="bottom"
      title={<div className={css.tooltipsText}>有效期至{expiryDate}</div>}
      color="white"
    >
      <span style={{ color: daysUntilExpiry < 30 ? 'red' : '' }}>
        剩余{daysUntilExpiry}天
      </span>
    </Tooltip>
  );
};

const HeaderDivider = memo(() => (
  <Divider
    type="vertical"
    style={{ borderLeft: '1px solid #ccc', height: '1em', top: '0' }}
  />
));

export const Header = memo(() => {
  const { state } = useAgentReducer(menuSelectionRef.current);
  const [isScroll, setIsScroll] = useState(false);
  const pageRef = useRef<HTMLElement | null>(null);
  const baseInfo = useAgentSelector(shopModelRef.current, s => s.baseInfo);

  const defaultTitle = state ? state.title : '';
  const { shopName, gnbbName, expireDay = '' } = baseInfo || {};
  const enterprisePage = isEnterprisePage(window.location.pathname);

  const renderTitle = useTitleRender(defaultTitle);

  const onScroll = useCallback(e => {
    const { scrollTop } = e.target;
    setIsScroll(scrollTop > 0);
  }, []);

  useEffect(() => {
    pageRef.current = document.getElementById('#page-content')!;
    pageRef.current.addEventListener('scroll', onScroll);

    if (enterprisePage) {
      document.title = `企业管理 - 智能跟单助手`;
    } else {
      document.title = `${shopName} - 智能跟单助手`;
    }

    return () => {
      pageRef.current!.removeEventListener('scroll', onScroll);
    };
  }, []);

  const renderEnterprisePageHeader = () => {
    return (
      <div className={css.headerInfo}>
        <ExportCenterInfo />
        <HelpManual />
      </div>
    );
  };

  const renderShopPageHeader = () => {
    return (
      <div className={css.headerInfo}>
        {gnbbName}
        <HeaderDivider />
        <ExpiryDate expiryDate={expireDay} />
        <HeaderDivider />
        <ExportCenterInfo />
        <HelpManual />
        <HeaderDivider />
        {shopName}
      </div>
    );
  };

  return (
    <div
      className={classNames(css.header, isScroll ? css.headerShadow : '')}
      id="#page-header"
    >
      <div>
        <div className={css.headerTitle}>{renderTitle()}</div>
      </div>
      {enterprisePage ? renderEnterprisePageHeader() : renderShopPageHeader()}
    </div>
  );
});
