import React, { memo } from 'react';
import { Dropdown } from '@/components/widgets/dropdown';
import { Menu, MenuItem } from '@/components/widgets/menu';
import { Icon } from '@/components/widgets/icon';

export const ItemClearDropdown = memo(
  (props: { clearItem: () => void; clearItemInStock: () => void }) => {
    const { clearItem, clearItemInStock } = props;

    return (
      <Dropdown
        overlay={
          <Menu>
            <MenuItem key="clearItem" onClick={clearItem}>
              清空全部商品的SKU
            </MenuItem>
            <MenuItem key="clearItemInstock" onClick={clearItemInStock}>
              清空仓库中商品的SKU
            </MenuItem>
          </Menu>
        }
        placement="bottomRight"
      >
        <div style={{ color: '#3d7eff', cursor: 'pointer' }}>
          <span>清空商品</span>
          <Icon type="xiala" style={{ marginLeft: '4px' }} />
        </div>
      </Dropdown>
    );
  }
);
