import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import css from './style.less';
import Collapse from 'antd/lib/collapse/Collapse';
import { ItemClearDropdown } from '../ItemClearDropdown';
import { VirtualDiv } from '@/components/layouts/virtualList';
import { Sku, SkuItem } from '../skuItem';
import { message } from '@/components/widgets/message';
import Loading from '@/components/widgets/loading/Loading';
import useDebounceFn from '@/hooks/useDebounceFn';
import { Search } from '../search';
import useSetState from '@/hooks/useSetState';
import { itemSkuRequest } from '@/requests/itemSku';
import {
  ItemSearchQuery,
  ItemWithSkusWebDto,
  SkuWebDto
} from '@/requests/itemSku/type';
import { warnError } from '@/components/infos';

const { Panel } = Collapse;

const SCROLL_TRIGGER_DISTANCE = 10;

type SkuSelectorProps = {
  limit?: number;
  taobaoSkuIds: string[];
  onChange?: (v: SkuWebDto[]) => void;
};

const foramtSkuFeild = (data: ItemWithSkusWebDto[]): ItemWithSkusWebDto[] => {
  return data.map(i => {
    const { picUrl, approveStatus } = i;
    return {
      ...i,
      skuList: i.skuList.map(s => ({ ...s, picUrl, approveStatus }))
    };
  });
};

export const SkuSelector = memo((props: SkuSelectorProps) => {
  const { limit = 5000, taobaoSkuIds, onChange } = props;

  const [loading, setLoading] = useState(false);
  const [itemWithSkus, setItemWithSkus] = useState<ItemWithSkusWebDto[]>([]);
  const [selectSkus, setSelectSkus] = useState<SkuWebDto[]>([]);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const [searchParams, setSearchParams] = useSetState<ItemSearchQuery>({
    page: 1,
    pageSize: 10,
    searchValue: '',
    searchApproveStatus: 'ONSALE'
  });
  const [total, setTotal] = useState(0);
  const scrollView = useRef<HTMLDivElement | null>(null);
  const isSearching = useRef(false);

  const skuIds = new Set(selectSkus?.map(item => item.taobaoSkuId));

  // 滚动左边商品列表
  const scrollTo = (position: number) => {
    const element = scrollView.current;
    if (element) {
      element.scrollTop = position;
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const { dataList, totalCount } = await itemSkuRequest.itemSkus({
        ...searchParams,
        page: 1
      });

      setActiveKeys(dataList.map(i => i.taobaoIid));
      scrollTo(0);
      setTotal(totalCount);
      setItemWithSkus(foramtSkuFeild(dataList));
    } catch (error) {
      warnError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (taobaoSkuIds.length) {
        const data = await itemSkuRequest.itemSkuDetails(taobaoSkuIds);
        const selectSkus = foramtSkuFeild(data).flatMap(i => i.skuList);

        onChange?.(selectSkus);
        setSelectSkus(selectSkus);
      }
    })();
  }, []);

  const handleSearchParamsChange = v => {
    setSearchParams(v);
  };

  const handleSearch = async () => {
    isSearching.current = true;
    init();
  };

  const removeItem = (id: string) => {
    const newSelectSkus = selectSkus.filter(item => item.taobaoIid !== id);

    setSelectSkus(newSelectSkus);
  };

  const handleSelectItem = (item: ItemWithSkusWebDto, checked) => {
    if (checked) {
      removeItem(item.taobaoIid);
      return;
    }

    if (item.skuList.length + selectSkus.length >= limit) {
      message.warning(`至多选择${limit}个sku`);
      return;
    }

    setSelectSkus(pre => [...new Set([...item.skuList, ...pre])]);
    onChange?.([...new Set([...item.skuList, ...selectSkus])]);
  };

  const removeSku = (sku: SkuWebDto) => {
    const newSelectSkus = selectSkus.filter(
      i => i.taobaoSkuId !== sku.taobaoSkuId
    );

    setSelectSkus(newSelectSkus);
    onChange?.(newSelectSkus);
  };

  const handleSelectSku = (sku: SkuWebDto) => {
    if (skuIds.has(sku.taobaoSkuId)) {
      removeSku(sku);
      return;
    }

    if (selectSkus.length >= limit) {
      message.warning(`至多选择${limit}个sku`);
      return;
    }

    setSelectSkus(pre => [...new Set([sku, ...pre])]);
    onChange?.([...new Set([sku, ...selectSkus])]);
  };

  const onScroll = useDebounceFn(async () => {
    if (isSearching.current) {
      isSearching.current = false;
      return;
    }
    const element = scrollView.current;
    if (!element) {
      return;
    }
    const position = element.scrollTop + element.clientHeight;
    if (element.scrollHeight - position <= SCROLL_TRIGGER_DISTANCE) {
      const { dataList, totalCount } = await itemSkuRequest.itemSkus({
        ...searchParams,
        page: searchParams.page + 1
      });
      setActiveKeys(pre => [...pre, ...dataList.map(i => i.taobaoIid)]);
      setItemWithSkus([...itemWithSkus, ...foramtSkuFeild(dataList)]);

      if (dataList.length > 0) {
        setSearchParams({ page: searchParams.page + 1 });
      }
    }
  }, 200);

  const handleClearSku = () => {
    setSelectSkus([]);
    onChange?.([]);
  };

  const handleClearSkuInStock = () => {
    const newSelectSkus = selectSkus.filter(i => i.approveStatus !== 'instock');

    setSelectSkus(newSelectSkus);
    onChange?.(newSelectSkus);
  };

  const renderItemSelceted = (
    selectSkus: SkuWebDto[],
    itemWithSku: ItemWithSkusWebDto
  ) => {
    const i = itemWithSku.skuList.map(i => i.taobaoSkuId);
    const s = selectSkus.map(i => i.taobaoSkuId);

    if (i.every(id => s.includes(id))) {
      return true;
    }

    return false;
  };

  return (
    <div className={css.wrapper}>
      <Search
        value={searchParams}
        onChange={handleSearchParamsChange}
        onSearch={handleSearch}
      />

      <div className={css.content}>
        <div className={css.card}>
          <div className={css.title}>查询商品 {total}件</div>
          <Loading loading={loading}>
            <div className={css.scroll} onScroll={onScroll} ref={scrollView}>
              <Collapse
                activeKey={activeKeys}
                bordered={false}
                onChange={v => setActiveKeys(v as string[])}
              >
                {itemWithSkus.map(itemWithSku => {
                  return (
                    <Panel
                      header={
                        <SkuItem
                          skuItem={itemWithSku}
                          type="wait"
                          selected={renderItemSelceted(selectSkus, itemWithSku)}
                          onSelect={item =>
                            handleSelectItem(
                              item,
                              renderItemSelceted(selectSkus, itemWithSku)
                            )
                          }
                        />
                      }
                      key={itemWithSku.taobaoIid}
                    >
                      {itemWithSku.skuList.map(sku => {
                        return (
                          <Sku
                            sku={sku}
                            skuPicUrl={itemWithSku.picUrl}
                            type="wait"
                            selected={skuIds.has(sku.taobaoSkuId)}
                            onSelect={handleSelectSku}
                            key={sku.taobaoSkuId}
                          />
                        );
                      })}
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Loading>
        </div>
        <div className={css.card}>
          <div className={css.title}>
            <div>已选SKU {skuIds.size}件</div>
            <ItemClearDropdown
              clearItem={handleClearSku}
              clearItemInStock={handleClearSkuInStock}
            />
          </div>
          <Loading loading={loading}>
            <VirtualDiv
              className={css.scroll}
              dataSource={selectSkus || []}
              itemHeight={56}
              renderItem={sku => (
                <Sku
                  skuPicUrl={(sku as SkuWebDto).picUrl}
                  sku={sku as SkuWebDto}
                  type="selected"
                  key={(sku as SkuWebDto).taobaoSkuId}
                  onSelect={() => removeSku(sku as SkuWebDto)}
                />
              )}
            />
          </Loading>
        </div>
      </div>
    </div>
  );
});
